import React from 'react'
import { Col, Form } from 'react-bootstrap'
import { isDate, isDecimal, isString } from '../utils'

/**
 * This function handles the popup that appears when selecting a cell with
 * space-containing values. The popup displays a set of choices on what
 * value should be taken in the cart JSON.
 */
export const ChoicesPopup = ({
  locale,
  missingDataFormatKey,
  selection,
  setSelection,
  customFields,
  currentField,
  top,
  left,
  dateDataFormatId,
  decimalDataFormatId,
  onDateDataFormatIdChange,
  onDecimalDataFormatIdChange,

  /**
   * These entries are built in `updateChoices` (Document.js).
   *
   * e.g. for strings:
   *
   *    {
   *      orderNoCust: [['Bestellung', 'Nr.', '1239876']],
   *      refCustomer: [['Firstname', 'Secondname', 'Lastname']],
   *      partNoMaxon: [
   *        ['velox-note-book'],
   *        ['velox-polo-shirt-black-s'],
   *        ['velox-polo-shirt-red-m'],
   *        ['velox-coffee-cup']
   *      ],
   *      partNoCust: [
   *        ['Velox', 'Note', 'Book'],
   *        ['VELOX', 'Polo', 'Shirt', 'Black', '(S)'],
   *        ['VELOX', 'Polo', 'Shirt', 'Red', '(M)'],
   *        ['Velox', 'Espresso-Cup']
   *      ],
   *      qty: [],
   *    }
   *
   *
   * e.g. for non-strings:
   *
   *    {
   *      deliveryDate: [[
   *        {
   *          id: '640729179652b71a4d27df4e',
   *          dataType: 'DATE',
   *          regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
   *          mask: 'MM/dd/yyyy',
   *          locale: null,
   *          displayName: { de: '(z.B. 11/17/2023)' }
   *        },
   *        {
   *          id: '640729299652b71a4d27df51',
   *          dataType: 'DATE',
   *          regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
   *          mask: 'dd/MM/yyyy',
   *          locale: null,
   *          displayName: { de: '(z.B. 17/01/2023)' }
   *        }
   *      ]]
   *    }
   */
  texts,

  /**
   * e.g.
   *
   *    {
   *      orderNoCust: [false, false, true],
   *      refCustomer: [true, true],
   *      partNoMaxon: [true],
   *      partNoCust: [true, false, false],
   *      qty: [],
   *    }
   */
  values,
  setValues,
}) => {
  const getUpdatedSelectionForKey = ({
    key,
    // e.g. [false, true, true]
    valuesForKey = values[key],
  }) => {
    /**
     * e.g.
     *
     *    [
     *      'Velox Note Book',
     *      'VELOX Polo Shirt Black (S)',
     *      'VELOX Polo Shirt Red (M)',
     *      'Velox Espresso-Cup',
     *    ]
     */
    const selectionForKey = selection[key]

    /**
     * e.g. for strings:
     *
     *    [
     *      ['Velox', 'Note', 'Book'],
     *      ['VELOX', 'Polo', 'Shirt', 'Black', '(S)'],
     *      ['VELOX', 'Polo', 'Shirt', 'Red', '(M)'],
     *      ['Velox', 'Espresso-Cup']
     *    ]
     *
     *
     * e.g. for non-strings:
     *
     *      [
     *        [
     *          {
     *            id: '640729179652b71a4d27df4e',
     *            dataType: 'DATE',
     *            regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
     *            mask: 'MM/dd/yyyy',
     *            locale: null,
     *            displayName: { de: '(z.B. 11/17/2023)' }
     *          },
     *          {
     *            id: '640729299652b71a4d27df51',
     *            dataType: 'DATE',
     *            regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
     *            mask: 'dd/MM/yyyy',
     *            locale: null,
     *            displayName: { de: '(z.B. 17/01/2023)' }
     *          }
     *        ]
     *      ]
     */
    const textsForKey = texts[key]

    // e.g. [0]
    const indexesToRemove = (valuesForKey || []).map((v, i) => !v && i).filter((x) => x !== false)

    /**
     * e.g.
     *
     *    [
     *      ['Note', 'Book'],
     *      ['Polo', 'Shirt', 'Black', '(S)'],
     *      ['Polo', 'Shirt', 'Red', '(M)'],
     *      ['Espresso-Cup']
     *    ]
     */
    const updatedTextsForKey = textsForKey.map(
      (
        // e.g. ['Velox', 'Note', 'Book']
        words
      ) =>
        // e.g. ['Note', 'Book']
        words.filter((_, i) => !indexesToRemove.includes(i))
    )

    /**
     * e.g. for strings:
     *
     *    [
     *      'Note Book',
     *      'Polo Shirt Black (S)',
     *      'Polo Shirt Red (M)',
     *      'Espresso-Cup',
     *    ]
     */
    return selectionForKey.map((text, i) => {
      if (text === '') {
        return undefined
      }

      const isTypeString = isString({ key, customFields })

      const joined = updatedTextsForKey[i]?.join(' ')

      return isTypeString ? joined : text
    })
  }

  const getSelection = () =>
    /**
     * e.g.
     *
     *    {
     *      orderNoCust: ['1239876'],
     *      refCustomer: ['Firstname Lastname'],
     *      partNoMaxon: [
     *        'velox-note-book',
     *        'velox-polo-shirt-black-s',
     *        'velox-polo-shirt-red-m',
     *        'velox-coffee-cup'
     *      ],
     *      partNoCust: ['Velox Note', 'VELOX Polo', 'VELOX Polo', 'Velox Espresso-Cup'],
     *      qty: [10, 5, 4, 2],
     *    }
     */
    Object.keys(customFields).reduce(
      (acc, key) => {
        const topForKey = top[key]
        const leftForKey = left[key]

        if (!topForKey || !leftForKey || !topForKey.length || !leftForKey.length) {
          return acc
        }

        /**
         * e.g.
         *
         *    [
         *      'Note Book',
         *      'Polo Shirt Black (S)',
         *      'Polo Shirt Red (M)',
         *      'Espresso-Cup',
         *    ]
         */
        const updatedSelectionForKey = getUpdatedSelectionForKey({ key })

        return {
          ...acc,
          [key]: updatedSelectionForKey,
        }
      },
      { ...selection }
    )

  const getValues = () =>
    /**
     * e.g.
     *
     *    {
     *      orderNoCust: [false, false, true],
     *      refCustomer: [true, true],
     *      partNoMaxon: [true],
     *      partNoCust: [true, false, false],
     *      qty: [],
     *    }
     */
    Object.keys(customFields).reduce((acc, key) => {
      /**
       * e.g. for strings:
       *
       *    ['Bestellung', 'Nr.', '1239876']
       *
       *
       * e.g. for non-strings:
       *
       *    [
       *      {
       *        id: '640729179652b71a4d27df4e',
       *        dataType: 'DATE',
       *        regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
       *        mask: 'MM/dd/yyyy',
       *        locale: null,
       *        displayName: { de: '(z.B. 11/17/2023)' }
       *      },
       *      {
       *        id: '640729299652b71a4d27df51',
       *        dataType: 'DATE',
       *        regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
       *        mask: 'dd/MM/yyyy',
       *        locale: null,
       *        displayName: { de: '(z.B. 17/01/2023)' }
       *      }
       *    ]
       */
      const textsForKey = texts[key][0]

      // e.g. [false, false, true];
      // no choices in the `undefined` case
      const valuesForKey = values[key] || []

      // there might be a mismatch between the available options and the chosen ones,
      // i.e. when the user clicks on a cell with different content than what's expected
      const isSizeDifferent = textsForKey ? valuesForKey.length !== textsForKey.length : false

      const isTypeString = isString({ key, customFields })

      if (!isTypeString) {
        // if date or decimal, we want only the stored dataFormat to be selected
        return acc
      }

      // default all to `true` if string
      const valuesForStrings = textsForKey ? Array(textsForKey.length).fill(true) : []

      return {
        ...acc,
        [key]: isSizeDifferent ? valuesForStrings : valuesForKey,
      }
    }, {})

  const handleChange = (key, i, isChecked) => {
    /**
     * e.g. for strings:
     *
     *    ['Bestellung', 'Nr.', '1239876']
     *
     *
     * e.g. for non-strings:
     *
     *    [
     *      {
     *        id: '640729179652b71a4d27df4e',
     *        dataType: 'DATE',
     *        regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
     *        mask: 'MM/dd/yyyy',
     *        locale: null,
     *        displayName: { de: '(z.B. 11/17/2023)' }
     *      },
     *      {
     *        id: '640729299652b71a4d27df51',
     *        dataType: 'DATE',
     *        regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
     *        mask: 'dd/MM/yyyy',
     *        locale: null,
     *        displayName: { de: '(z.B. 17/01/2023)' }
     *      }
     *    ]
     */
    const textsForKey = texts[key][0]

    const isTypeDate = isDate({ key, customFields })
    const isTypeDecimal = isDecimal({ key, customFields })

    if (isTypeDate) {
      // save the chosen date format;
      // e.g. 'a1'
      return onDateDataFormatIdChange(textsForKey[i].id)
    }

    if (isTypeDecimal) {
      // save the chosen decimal format;
      // e.g. 'a1'
      return onDecimalDataFormatIdChange(textsForKey[i].id)
    }

    // e.g. [true, true, true]
    const valuesForKey = values[key]

    // e.g. [false, true, true]
    const updatedValuesForKey = valuesForKey.map((value, j) =>
      i === j
        ? // this is the one that the user modified (selected or deselected it);
          // for strings, set it to `true` or `false` according to whether the user selected or deselected it
          isChecked
        : // leave the rest as it is
          value
    )

    /**
     * e.g.
     *
     *    [
     *      'Note Book',
     *      'Polo Shirt Black (S)',
     *      'Polo Shirt Red (M)',
     *      'Espresso-Cup',
     *    ]
     */
    const updatedSelectionForKey = getUpdatedSelectionForKey({
      key,
      valuesForKey: updatedValuesForKey,
    })

    setSelection((prev) => ({
      ...prev,
      [key]: updatedSelectionForKey,
    }))

    setValues((prev) => ({
      ...prev,
      [key]: updatedValuesForKey,
    }))
  }

  const getIsChecked = (j, key) => {
    // e.g. [true, false, false]
    const valuesForKey = values[key]

    const isTypeDate = isDate({ key, customFields })
    const isTypeString = isString({ key, customFields })

    if (isTypeString) {
      return !!valuesForKey[j]
    }

    const dataFormat = texts[key][0][j]

    return dataFormat.id === (isTypeDate ? dateDataFormatId : decimalDataFormatId)
  }

  React.useEffect(() => {
    const updatedValues = getValues()

    setValues(updatedValues)
  }, [texts])

  React.useEffect(() => {
    const updatedSelection = getSelection()

    setSelection(updatedSelection)
  }, [values])

  return (
    <div key={Math.random()} className="uta-selection-checkbox">
      {Object.keys(customFields).map((key) => {
        const topForKey = top[key]
        const leftForKey = left[key]

        const shouldDisplayForm =
          texts[key] && texts[key].length > 0 && topForKey.length > 0 && leftForKey.length > 0

        if (!shouldDisplayForm) {
          return null
        }

        /**
         * e.g. for strings:
         *
         *    ['Bestellung', 'Nr.', '1239876']
         *
         * e.g. for non-strings:
         *
         *    [
         *      {
         *        id: '640729299652b71a4d27df51',
         *        dataType: 'DATE',
         *        regex: '\\b(0?[1-9]|[12][0-9]|30|31)/(0?[1-9]|1[0-2]])/20[0-9]{2}',
         *        mask: 'dd/MM/yyyy',
         *        locale: null,
         *        displayName: { de: '(z.B. 17/01/2023)' }
         *      }
         *    ]
         */
        const textsForKey = texts[key][0]

        const isTypeString = isString({ key, customFields })

        return (
          <Col md={3} key={Math.random()} className="column">
            <div key={Math.random()} className="uta-button-container">
              {shouldDisplayForm &&
                Array(textsForKey).map((text, i) => (
                  <div
                    key={Math.random()}
                    className={`form mb-3`}
                    style={{
                      top: topForKey[i],
                      left: leftForKey[i],
                      display:
                        (missingDataFormatKey === key || currentField === key) && text.length > 1
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {text.map(
                      (
                        /**
                         * e.g. for strings: 'Bestellung'
                         *
                         * e.g. for non-strings:
                         *
                         *    {
                         *      id: 'c3',
                         *      dataType: 'DATE',
                         *      regex: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
                         *      displayName: 'dd/MM/yyyy (e.g. 31/12/2022)',
                         *      mask: 'dd/MM/yyyy',
                         *    }
                         */
                        word,
                        j
                      ) =>
                        !word ? null : (
                          <Form.Check
                            key={Math.random()}
                            className="checkbox"
                            radioGroup={key}
                            name={key}
                            label={`${
                              isTypeString
                                ? // if string, just print the word
                                  word
                                : // if not, we assume that this is a dataFormat
                                  word.displayName[locale] || word.displayName.en
                            }`}
                            type={isTypeString ? `checkbox` : `radio`}
                            id={`default-${word}-${j}`}
                            onChange={({ target: { checked: isChecked } }) =>
                              handleChange(key, j, isChecked)
                            }
                            checked={getIsChecked(j, key)}
                          />
                        )
                    )}
                  </div>
                ))}
            </div>
          </Col>
        )
      })}
    </div>
  )
}
