import React from 'react'

const TableItem = ({ item }) => {
  return (
    <tr>
      <td colSpan="3">{item.articleNumber}</td>
      <td colSpan="3" className="uta-table-quantity">
        {item.quantity}
      </td>
      <td colSpan="6">{item.comment}</td>
    </tr>
  )
}

export default TableItem
