import { RequiredFields } from '../types'

export const ENV =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
    ? process.env
    : window._env_

export const requiredFields = {
  orderNr: {
    type: 'header',
    labels: {
      en: 'Order Number',
      de: 'Bestellreferenz',
    },
    defaults: {
      relevancy: 0,
      mustHaveSameLength: true,
      isUnique: true,
      minLength: 5,
      isQuantityFormat: false,
      alignment: 'left',
    },
  },
  artNr: {
    type: 'item',
    labels: {
      en: 'Article Number',
      de: 'Artikelnummer',
    },
    defaults: {
      relevancy: 13,
      mustHaveSameLength: false,
      isUnique: false,
      minLength: 3,
      isQuantityFormat: false,
      alignment: 'left',
    },
  },
  qty: {
    type: 'item',
    labels: {
      en: 'Quantity',
      de: 'Bestellmenge',
    },
    defaults: {
      relevancy: 6,
      mustHaveSameLength: false,
      isUnique: false,
      minLength: 1,
      isQuantityFormat: true,
      alignment: 'right',
    },
  },
  artRef: {
    type: 'item',
    labels: {
      en: 'Article Reference',
      de: 'Artikelreferenz',
    },
    defaults: {
      relevancy: 13,
      mustHaveSameLength: false,
      isUnique: false,
      minLength: 5,
      isQuantityFormat: false,
      alignment: 'left',
    },
  },
}
