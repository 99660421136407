import { requiredFields } from '../config'
import { parse, format } from 'date-fns/esm'
import { itCH, enUS, de } from 'date-fns/esm/locale'

const LOCALES = {
  de,
  de_CH: itCH,
  en: enUS,
}

export const DATATYPE_STRING = 'STRING'
export const DATATYPE_DATE = 'DATE'
export const DATATYPE_DECIMAL = 'DECIMAL'
export const MAX_NUMBER_OF_DECIMALS = 2

export const ERRORS = {
  NEEDS_REQUIRED_FIELDS: 'needsRequiredFields',
  NEEDS_DECIMAL_DATA_FORMAT: 'needsDecimalDataFormat',
  NEEDS_DATE_DATA_FORMAT: 'needsDateDataFormat',
}

const SEPARATORS = [' ', ':', ';']

export const getEditDistance = (a, b) => {
  // based on Levenshtein's algorithm
  if (a.length === 0) return b.length
  if (b.length === 0) return a.length
  let tmp, i, j, prev, val, row
  // swap to save some memory O(min(a,b)) instead of O(a)
  if (a.length > b.length) {
    tmp = a
    a = b
    b = tmp
  }

  row = Array(a.length + 1)
  // init the row
  for (i = 0; i <= a.length; i++) {
    row[i] = i
  }

  // fill in the rest
  for (i = 1; i <= b.length; i++) {
    prev = i
    for (j = 1; j <= a.length; j++) {
      if (b[i - 1] === a[j - 1]) {
        val = row[j - 1] // match
      } else {
        val = Math.min(
          row[j - 1] + 1, // substitution
          Math.min(
            prev + 1, // insertion
            row[j] + 1
          )
        ) // deletion
      }
      row[j - 1] = prev
      prev = val
    }
    row[a.length] = prev
  }
  return row[a.length]
}

export const isNotBlank = (str) => str.trim().length

export const splitValue = (text) =>
  (text || '').split(new RegExp(SEPARATORS.join('|'), 'g')).filter((s) => isNotBlank(s))

export const processHtml = (text, url) =>
  text
    .replace(/'/g, '')
    .replace(
      /<\/table>\n\s+<h2 class="pagenumber">Page [0-9]+<\/h2>\n\s+<table class="table table-striped table-bordered">/g,
      ''
    )
    .replace(
      /<!DOCTYPE html >\n\s+<html lang="en">\n\s+<head>\n\s+<meta http-equiv="X-UA-Compatible" content="IE=Edge" \/>\n\s+<meta charset="utf-8" \/>\n\s+<\/head>\n\s+\n\s+<body style="margin: 0;">\n\s+/g,
      ''
    )
    .replace(/<\/body>\n\s+<\/html>\n\s+/g, '')
    .replace(
      /<object width="[0-9]+" height="[0-9]+" data="[0-9]+\/[0-9]+\.svg" type="image\/svg\+xml" id="pdf[0-9]+" style="[a-z0-9():;-\s]+"><\/object>/g,
      ''
    )

    // fonts for buildvu
    .replaceAll('url("fonts', `url("${url}fonts`)

    // replacing white with black, as in 111536b.pdf;
    // https://gitlab.com/sly-ag/sly-connect/-/issues/144
    .replaceAll('rgb(255,255,255);', 'rgb(0,0,0);')
    .replaceAll('rgba(255, 255, 255, 0);', 'rgb(0,0,0);')
    .replaceAll('#FFFFFF', '#000')
    .replaceAll('#FFF', '#000')
    .replaceAll('#ffffff', '#000')
    .replaceAll('#fff', '#000')

export const getSimilarity = ({ format, key, customFields }) => {
  let keyFormat = format[key]

  if (!keyFormat || !keyFormat.relevancy) {
    keyFormat = customFields[key]?.defaults
  }

  return (keyFormat && +keyFormat.relevancy) || 5
}

export const getAlignment = ({ key, customFields }) =>
  customFields[key]?.defaults.alignment || 'left'

export const getIsUnique = ({ format, key, customFields }) => {
  let keyFormat = format[key]

  if (!keyFormat || !keyFormat.isUnique) {
    keyFormat = customFields[key]?.defaults
  }

  return (keyFormat && keyFormat.isUnique) || false
}

export const getDefaultFormat = (conf) => {
  return Object.keys(conf || requiredFields).reduce((acc, field) => {
    return {
      ...acc,
      [field]: {
        relevancy: +(conf || requiredFields)[field].defaults.relevancy,
        alignment: (conf || requiredFields)[field].defaults.alignment,
      },
    }
  }, {})
}

export const getInitialSelection = (fields) => {
  let selection = {}

  Object.keys(fields).forEach((key) => (selection[key] = []))

  return selection
}

export const getFirstKey = (conf) => {
  return Object.keys(conf) && Object.keys(conf).length > 0 ? Object.keys(conf)[0] : undefined
}

export const getLastKey = (conf) => {
  return Object.keys(conf) && Object.keys(conf).length > 0
    ? Object.keys(conf)[Object.keys(conf).length - 1]
    : undefined
}

export const hasBeenSelected = (key, selection) => !!selection[key]?.length

export const isRequired = (key, fields) => fields[key].required

export const getRequiredKeys = (fields) =>
  Object.keys(fields).filter((key) => isRequired(key, fields))

// returns the leading field or the first key of "item" type
export const getLeadingItemKey = (fields) =>
  Object.keys(fields).find((key) => fields[key].isLeading) ||
  Object.keys(fields).find((key) => fields[key].type === 'item')

// ------- DATA FORMATS -------
export const getDataType = ({ key, customFields }) => customFields[key]?.dataType || DATATYPE_STRING

export const isDate = ({ key, customFields }) =>
  getDataType({ key, customFields }) === DATATYPE_DATE
export const isDecimal = ({ key, customFields }) =>
  getDataType({ key, customFields }) === DATATYPE_DECIMAL
export const isString = ({ key, customFields }) =>
  getDataType({ key, customFields }) === DATATYPE_STRING

export const getFirstDateKey = (fields) =>
  Object.keys(fields).find((k) => isDate({ key: k, customFields: fields }))
export const getFirstDecimalKey = (fields) =>
  Object.keys(fields).find((k) => isDecimal({ key: k, customFields: fields }))

export const getMatchingFormats = ({ value, type, dataFormats }) =>
  /**
   * e.g.
   *
   *    [
   *      {
   *        id: 'd4',
   *        dataType: 'DATE',
   *        regex: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
   *        displayName: 'MM/dd/yyyy (e.g. 12/31/2022)',
   *        mask: 'MM/dd/yyyy',
   *      }
   *    ]
   */
  dataFormats
    // first, get only the dataFormats with the same dataType
    .filter((f) => f.dataType === type)
    // then, get all the dataFormats whose regexes match with the input value
    .filter((f) =>
      value.match(
        new RegExp(
          f.regex,
          // case-insensitive matching
          'i'
        )
      )
    )

// e.g. "Preis 123’456,78" => 123456.78
export const parseNumber = ({
  // e.g. "Preis 123’456,78"
  value,

  // dataFormat object to use for the parsing
  dataFormat,
}) => {
  if (!dataFormat) {
    // probably an old format; nothing to do
    return value
  }

  const { mask: decimalSeparator } = dataFormat

  // e.g. [123’456,78", ...]
  const matched = value
    // 1. strip out all non-numeric characters
    .match(new RegExp("\\d[\\d, \\.'’]*"))

  if (!matched) {
    // nothing to do
    return value
  }

  const matchedNumber = matched[0]

  const lastIndexOfSeparator = matchedNumber.lastIndexOf(decimalSeparator)

  const replaced =
    lastIndexOfSeparator > 0
      ? // 2a. replace the decimal separator with something specific, that we can replace again later
        matchedNumber.substring(0, lastIndexOfSeparator) +
        '~' +
        matchedNumber.substring(lastIndexOfSeparator + 1, matchedNumber.length)
      : // 2b. integer; do nothing
        matchedNumber

  const parsed = parseFloat(
    replaced
      // 3. strip out the thousands separators
      .replace('’', '')
      .replace("'", '')
      .replace(',', '')
      .replace('.', '')
      .replace(' ', '')
      // 4. separate decimals with a point (.)
      .replace('~', '.')
  )

  const rounded = Math.round(parsed * 100) / 100

  return rounded
}

// e.g. for the US date format: "12/01/2022" => "2022-12-01"
export const convertDate = ({
  // e.g. "am 12/01/2022"
  value,

  // dataFormat object from which to convert
  fromDataFormat,

  // dataFormat object to which to convert
  toDataFormat,
}) => {
  if (!toDataFormat?.mask) {
    // no usable target dataFormat;
    // it might be missing from the custom configuration
    return value
  }

  if (!fromDataFormat) {
    // unlikely; this means that the dataFormats have been recreated on the DB, i.e. they have new IDs
    return value
  }

  // we need to `.match` because `value` might contain non-date words like "am " that we wanna filter out;
  // e.g. ["12/01/2022", ...] (i.e. Dec 1st 2022)
  const valueMatched =
    // e.g. "am 12/01/2022"
    value.match(
      new RegExp(
        // e.g. "[0-9]{2}/[0-9]{2}/[0-9]{4}"
        fromDataFormat.regex,
        // case-insensitive matching
        'i'
      )
    )

  if (!valueMatched) {
    // we weren't able to parse the date with the stored dataFormat;
    // this means that the current PDF has a different format for dates than the previous one;
    // to fix this, the user should select the date field again
    // TODO this is of course not ideal; best thing would be to select a new dataFormat automatically,
    // but what if there's 2 or more matching dataFormats?
    return value
  }

  // date object;
  // e.g. Thu Dec 01 2022 00:00:00 GMT+0100 (Central European Standard Time);
  const parsedDate = parse(valueMatched[0], fromDataFormat.mask, new Date(), {
    locale: LOCALES[fromDataFormat.locale],
  })

  // e.g. "2022-01-12" (i.e. ISO 8601)
  return format(parsedDate, toDataFormat.mask)
}
// ------- END DATA FORMATS -------

// https://stackoverflow.com/questions/27746304/how-to-check-if-an-object-is-a-promise
export const isPromise = (func) => !!func?.then

export const logCart = (cartJson: Cart) => console.log({ cartJson })

// TODO there's a similar function in `ChoicesPopup.js` (`getUpdatedSelectionForKey`); replace it with this one where possible
export const parseValue = ({ field: { regex, dataType }, value, choices }) => {
  const matched =
    regex && value.match(new RegExp(regex, 'g'))
      ? value.match(new RegExp(regex, 'g')).join('')
      : value

  if (dataType === 'DATE') {
    // no further processing for dates
    return matched
  }

  // e.g. [0, 1]
  const indexesToRemove = (choices || []).map((v, i) => !v && i).filter((x) => x !== false)

  // e.g. ['Ihre', 'Materialnummer', '4432']
  const split = splitValue(matched)

  // e.g. ['4432']
  const parsed = split.filter((_, i) => {
    return !indexesToRemove.includes(i)
  })

  // e.g. '4432'
  return parsed.join(' ')
}

export const renameFeatureSwitches = (switches) => ({
  isTutorialEnabled: !switches.DISABLED_TUTORIAL,
  areLabelsEnabled: switches.LABEL,
  isLayoutCompact: switches.COMPACT_LAYOUT,
  isErrorsBadgeEnabled: switches.ERRORS_BADGE,
})
