import replaceAllInserter from 'string.prototype.replaceall'

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ENV, requiredFields } from './config'
import { Buttons, Document, Dropzone, Tutorial } from './components'
import {
  convertDate,
  ERRORS,
  getAlignment,
  getDefaultFormat,
  getFirstDateKey,
  getFirstDecimalKey,
  getFirstKey,
  getInitialSelection,
  getLeadingItemKey,
  getRequiredKeys,
  getSimilarity,
  hasBeenSelected,
  isDate,
  isDecimal,
  isPromise,
  isRequired,
  isString,
  logCart,
  parseNumber,
  parseValue,
  renameFeatureSwitches,
} from './utils'
import Table from './components/Table'
import { api } from './api'
import {
  CART_DATE_FORMAT_MOCK,
  choicesMocks,
  CURRENT_MOCK,
  dataFormatsMock,
  DATE_DATA_FORMAT_ID_MOCK,
  DECIMAL_DATA_FORMAT_ID_MOCK,
  fieldsMocks,
  formatMocks,
} from './mocks'

const BACKEND_BASE_URL = ENV.REACT_APP_BACKEND_BASE_URL
const USE_BACKEND = !!parseInt(process.env.REACT_APP_USE_BACKEND || '0')
const IS_TEST = process.env.NODE_ENV === 'test'
const IS_PROD = process.env.NODE_ENV === 'production'
const { REACT_APP_USE_MOCKS: USE_MOCKS } = process.env

// `.replaceAll` polyfill
// https://stackoverflow.com/questions/65295584/jest-typeerror-replaceall-is-not-a-function
replaceAllInserter.shim()

const App = (props) => {
  const HeaderRef = React.createRef()

  // TODO we have way too many state vars; consider useReducer and useContext

  const [featureSwitches, setFeatureSwitches] = useState({
    isTutorialEnabled: true,
    areLabelsEnabled: true,
    isLayoutCompact: false,
    isErrorsBadgeEnabled: true,
  })
  const [isTutorialExpanded, setIsTutorialExpanded] = useState(true)

  const [isLayoutCompact, setIsLayoutCompact] = useState(true)

  const [hasUploaded, setHasUploaded] = useState(false)
  const [isPDF, setIsPDF] = useState(undefined)
  const [isInteractive, setIsInteractive] = useState(true)

  // current field being selected (i.e. currently pressed button)
  const [currentField, setCurrentField] = useState('orderNr')

  // TODO rename to `baseFields`?
  const [customFields, setCustomFields] = useState({})

  // collection of values that appear in the cart JSON
  const [selection, setSelection] = useState({})

  // list of options for the current popup
  const [choicesTexts, setChoicesTexts] = useState({})

  // selected options from the popup
  const [choicesValues, setChoicesValues] = useState({})

  const [cart, setCart] = useState({})
  const [locale, setLocale] = useState(props.locale || 'en')
  const [isCartVisible, setIsCartVisible] = useState(false)
  const [data, setData] = useState([])
  const [customerId, setCustomerId] = useState()

  // used in Netscan
  const [cacheKey, setCacheKey] = useState()

  const [shopId, setShopId] = useState()
  const [format, setFormat] = useState({})

  // contains all the possible data formats for DATEs and DECIMALs
  const [dataFormats, setDataFormats] = useState([])

  // key for which a dataFormat has yet to be selected
  const [missingDataFormatKey, setMissingDataFormatKey] = useState()

  /**
   * Used to convert all dates when importing the cart.
   *
   * e.g.
   *
   *    {
   *      id: '64071aee8ea6172958c029f4',
   *      dataType: 'DATE',
   *      regex: '\\d{4}-\\d{2}-\\d{2}',
   *      mask: 'yyyy-MM-dd',
   *      locale: null,
   *      displayName: { de: '(z.B. 2022-12-31)' },
   *    },
   */
  const [cartDateFormat, setCartDateFormat] = useState({})

  // ID of the dataFormat used to parse dates in the PDF; customer-specific;
  // can be null, in which case we don't perform any date manipulation when creating the cart
  const [dateDataFormatId, setDateDataFormatId] = useState('')

  // ID of the dataFormat used to parse numbers in the PDF; customer-specific
  const [decimalDataFormatId, setDecimalDataFormatId] = useState('')

  const [formatUrl, setFormatUrl] = useState()
  const [isLatestFormatVersion, setIsLatestFormatVersion] = useState(true)
  const [isFormatChecked, setIsFormatChecked] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [uploadError, setUploadError] = useState()
  const [errors, setErrors] = useState()
  const [tutCurrentStep, setTutCurrentStep] = useState('orderNr-click-location')
  const [headerHeight, setHeaderHeight] = useState(0)
  const [isAdvancedViewShown, setIsAdvancedViewShown] = useState(false)

  // to limit the number of PATCHes performed
  const [patchTimeoutId, setPatchTimeoutId] = useState()

  var pjson = require('../package.json')
  const [latestFormatVersion, setLatestFormatVersion] = useState(pjson.version)

  useEffect(() => {
    if (!HeaderRef?.current) {
      return
    }

    const height = HeaderRef.current.getBoundingClientRect().height

    setHeaderHeight(height)
  }, [HeaderRef])

  useEffect(() => {
    if (props.shopId) {
      setShopId(props.shopId)
    }

    onConfigurationLoad(props.shopId)
  }, [props.shopId])

  useEffect(() => {
    onFormatLoad(props.customerId, props.shopId)
  }, [customFields])

  useEffect(() => {
    if (!Object.keys(customFields).length) {
      return
    }

    // netscan/cache
    if (props.customerId && props.cacheKey) {
      setCacheKey(props.cacheKey)

      onLoadFromCache(props.cacheKey)
    }
  }, [customFields])

  useEffect(() => {
    if (props.customerId) {
      setCustomerId(props.customerId)
    }
  }, [props.customerId])

  useEffect(() => {
    if (!!props.useDefaultCSS) {
      require('./App.scss')
    }
  }, [props.useDefaultCSS])

  useEffect(() => {
    if (format && Object.keys(format).length) {
      onFormatSave()
    }
  }, [format])

  useEffect(() => {
    onFormatSave()
  }, [choicesValues])

  useEffect(() => {
    onFormatSave()
    setErrors(getErrors())
  }, [dateDataFormatId, decimalDataFormatId])

  useEffect(() => {
    setErrors(getErrors())
  }, [selection])

  React.useEffect(() => {
    if (errors?.includes(ERRORS.NEEDS_DECIMAL_DATA_FORMAT)) {
      // it doesn't really matter which decimal key we get, just take the first
      const decimalKey = getFirstDecimalKey(customFields)

      return setMissingDataFormatKey(decimalKey)
    }

    if (errors?.includes(ERRORS.NEEDS_DATE_DATA_FORMAT)) {
      // it doesn't really matter which date key we get, just take the first
      const dateKey = getFirstDateKey(customFields)

      return setMissingDataFormatKey(dateKey)
    }
  }, [errors])

  const onLoadFromCache = async (key) => {
    if (!USE_BACKEND) {
      if (!IS_TEST) {
        console.warn(
          '***** Not going to connect to the backend, see REACT_APP_USE_BACKEND inside the .env file.'
        )
      }

      return
    }

    try {
      // TODO this is not an upload, so create a function `api.loadFromCache` that loads a document from cache instead of using `api.upload`
      await api.upload({
        useMocks: !!parseInt(USE_MOCKS),
        file: undefined,
        onSuccess: onFileDropped,
        onError: onFileUploadError,
        key,
      })
    } catch (e) {
      // TODO handle error
    }
  }

  const getFeatureSwitches = async (shopId) => {
    const { data: switches } = await axios.get(`${BACKEND_BASE_URL}/v1/features?shopId=${shopId}`)

    setFeatureSwitches(renameFeatureSwitches(switches))
  }

  const getConfiguration = async (shopId) => {
    const url = `${BACKEND_BASE_URL}/configuration/search/findByShopId?shopId=${shopId}${
      IS_PROD ? '' : `&rand=${Math.random()}`
    }`

    const { data } = await axios.get(url)

    return data
  }

  const getDataFormats = async () => {
    const url = `${BACKEND_BASE_URL}/dataformats`

    const { data } = await axios.get(url)

    return data._embedded.dataFormat
  }

  const onConfigurationLoad = async (shopId) => {
    if (!USE_BACKEND) {
      console.warn(
        '***** Not going to connect to the backend, see REACT_APP_USE_BACKEND inside the .env file.'
      )

      if (USE_MOCKS) {
        setCustomFields(fieldsMocks[CURRENT_MOCK])
        setDataFormats(dataFormatsMock)
        setCartDateFormat(CART_DATE_FORMAT_MOCK)
      } else {
        setCustomFields(requiredFields)
        setDataFormats([])
        setCartDateFormat({})
      }

      setFeatureSwitches((prev) => ({ ...prev, areLabelsEnabled: true }))
      setIsAdvancedViewShown(true)

      return
    }

    try {
      const dataFormats = await getDataFormats()
      setDataFormats(dataFormats)

      await getFeatureSwitches(props.shopId)

      const configuration = await getConfiguration(shopId)

      const initialSelection = getInitialSelection(configuration.fields)
      setCustomFields(configuration.fields)

      setCartDateFormat(dataFormats.filter((df) => df.id === configuration.dateFormat)[0] || {})

      const initialSelectionKeys = Object.keys(initialSelection)

      if (initialSelectionKeys[0]) {
        setCurrentField(initialSelectionKeys[0])
        setTutCurrentStep(`${initialSelectionKeys[0]}-click-location`)
      }
    } catch (e) {
      // probably due to backend down
      if (!e.response || (e.response && (e.response.status === 500 || e.response.status === 503))) {
        setUploadError('no-server-response')
      }

      setCustomFields(requiredFields)
      setIsAdvancedViewShown(true)
    }
  }

  // TODO this is only needed to stringify `labelPosition` in the database;
  // it can be removed when a better db schema is implemented
  const stringify = (format) => {
    const stringified = Object.keys(format).reduce((acc, k) => {
      return {
        ...acc,
        [k]: {
          ...format[k],
          labelPosition: JSON.stringify(format[k].labelPosition || {}),
        },
      }
    }, {})

    return stringified
  }

  // TODO this is only needed to destringify `labelPosition` from the database;
  // it can be removed when a better db schema is implemented
  const destringify = (format) => {
    const destringified = Object.keys(format).reduce((acc, k) => {
      return {
        ...acc,
        [k]: {
          ...format[k],
          labelPosition: JSON.parse(format[k].labelPosition || '{}'),
        },
      }
    }, {})

    return destringified
  }

  const onFormatLoad = async (customerId, shopId) => {
    if (!USE_BACKEND) {
      if (!IS_TEST) {
        console.warn(
          '***** Not going to connect to the backend, see REACT_APP_USE_BACKEND inside the .env file.'
        )
      }

      let initialSelection

      if (USE_MOCKS) {
        initialSelection = getInitialSelection(fieldsMocks[CURRENT_MOCK])
        setChoicesValues(choicesMocks[CURRENT_MOCK])
        setFormat(destringify(formatMocks[CURRENT_MOCK]))
        setDateDataFormatId(DATE_DATA_FORMAT_ID_MOCK)
        setDecimalDataFormatId(DECIMAL_DATA_FORMAT_ID_MOCK)
      } else {
        initialSelection = getInitialSelection(requiredFields)
        setChoicesValues(initialSelection)
        setFormat(getDefaultFormat())
      }

      setChoicesTexts(initialSelection)
      setIsAdvancedViewShown(true)
      setSelection(initialSelection)

      return
    }

    let initialSelection = getInitialSelection(customFields)
    setSelection(initialSelection)
    setChoicesTexts(initialSelection)
    setChoicesValues(initialSelection)

    try {
      let url = `${BACKEND_BASE_URL}/formats/search/getByCustomerIdAndShopId?customerId=${customerId}&shopId=${shopId}&rand=${Math.random()}`
      if (BACKEND_BASE_URL.includes('apsoparts')) {
        url = `${BACKEND_BASE_URL}/formats/search/findByCustomerId?customerId=${customerId}&rand=${Math.random()}`
      }
      const { data } = await axios.get(url)

      if (data) {
        setFormatUrl(data._links.self.href)

        if (data.format && data.format.mapping) {
          setFormat(destringify(data.format.mapping))
        } else {
          setFormat(getDefaultFormat(customFields))
        }

        if (data.format?.dateDataFormatId) {
          // TODO
          // 1. check that dataformat exists
          // 2. if not, ask user to choose one

          // 3. set either the chosen one or the config one in the state
          setDateDataFormatId(data.format?.dateDataFormatId)
        }

        if (data.format?.decimalDataFormatId) {
          // TODO
          // 1. check that dataformat exists
          // 2. if not, ask user to choose one

          // 3. set either the chosen one or the config one in the state
          setDecimalDataFormatId(data.format?.decimalDataFormatId)
        }

        if (data.format && data.format.selection) {
          setChoicesValues(data.format.selection)
        }

        setIsTutorialExpanded(false)

        if (!data.version || data.version !== latestFormatVersion) {
          setIsLatestFormatVersion(false)
        }
      } else {
        setFormat(getDefaultFormat(customFields))
        setIsAdvancedViewShown(true)
      }
    } catch (e) {
      // probably due to 404 or backend down
      setFormat(getDefaultFormat(customFields))
      setIsAdvancedViewShown(true)
    }
  }

  const onFieldSelectorClick = (key) => {
    // TODO consider useReducer
    setIsCartVisible(false)
    setIsInteractive(true)
    setCurrentField(key)

    // reset the selection for this key
    resetSelection({ key })

    // reset missing dataFormat key
    setMissingDataFormatKey(null)

    // reset the choices texts for this key
    setChoicesTexts((prev) => ({ ...prev, [key]: [] }))

    // reset the format for this key
    setFormat((prev) => ({
      ...prev,
      [key]: {
        relevancy: getSimilarity({ format: prev, key, customFields }),
        top: undefined,
        left: undefined,
        right: undefined,
        labelPosition: {},
        labelText: undefined,
      },
    }))

    // TODO separate concerns
    onTutNext({ key, next: 'location' })
  }

  const onFieldSelected = ({ key, value }) => {
    const parsedValue = parseValue({
      field: customFields[key],
      value,
      choices: choicesValues[key],
    })

    setSelection((prev) => ({
      ...prev,
      [key]: [...(prev[key] || []), parsedValue],
    }))
  }

  const resetSelection = ({ key }) => {
    setSelection((prev) => ({ ...prev, [key]: [] }))
  }

  const onDateDataFormatIdChange = (id) => {
    setDateDataFormatId(id)
  }

  const onDecimalDataFormatIdChange = (id) => {
    setDecimalDataFormatId(id)
  }

  // TODO rename to `onLayoutChange`
  // TODO rename `relevancy` to `similarity`
  const onFormatChange = ({
    key,
    value,
    valuePosition,
    label,
    labelPosition,
    // TODO separate concerns
    updateTut = true,
  }) => {
    setIsCartVisible(false)

    if (key.includes('-')) {
      // triggered by sliding the similarity bar
      // TODO consider moving this to a separate function
      const [fieldName] = key.split('-')

      setFormat((prev) => ({
        ...prev,
        [fieldName]: { ...prev[fieldName], relevancy: +value },
      }))

      return
    }

    // triggered by clicking on the table cells or by choosing a different dataFormat through the popup radiobuttons
    const formatForKey = format[key]

    setFormat((prev) => ({
      ...prev,
      [key]: {
        ...formatForKey,
        relevancy: getSimilarity({ format, key, customFields }),
        alignment: getAlignment({ key, customFields }),
        left: (valuePosition || formatForKey).left,
        top: (valuePosition || formatForKey).top,
        right: (valuePosition || formatForKey).right,
        labelPosition: labelPosition || formatForKey.labelPosition,
        labelText: label || formatForKey.label,
      },
    }))

    // TODO the "tutorial" concern should be separated by the "format" concern
    if (updateTut) {
      onTutNext({ key, next: 'next' })
    }
  }

  const isInitial = (selection) =>
    Object.keys(selection).reduce((acc, k) => acc && !selection[k].length, true)

  const onFormatSave = () => {
    setIsCartVisible(false)

    if (!customerId || !shopId) {
      return
    }

    if (isInitial(selection)) {
      return
    }

    if (!USE_BACKEND) {
      if (!IS_TEST) {
        console.warn(
          '***** Not going to connect to the backend, see REACT_APP_USE_BACKEND inside the .env file.'
        )
      }

      return
    }

    window.clearTimeout(patchTimeoutId)

    const body = {
      format: {
        mapping: stringify(format),
        selection: choicesValues,
        dateDataFormatId,
        decimalDataFormatId,
      },
      customerId,
      shopId,
      version: latestFormatVersion,
    }

    /**
     * `formatUrl` is defined only after we make an initial `POST` to create a new format.
     *
     * If defined, `formatUrl` is gonna be something like:
     *
     *   'http://sly-connect-test-nlb-d057f3ba68484612.elb.eu-central-1.amazonaws.com/upload-service-f/formats/63f3a9fa21746d66f24853d8'
     */
    const method = formatUrl ? 'put' : 'post'

    /**
     * e.g.
     *
     *   [
     *     "http:",
     *     "",
     *     "sly-connect-test-nlb-d057f3ba68484612.elb.eu-central-1.amazonaws.com",
     *     "upload-service-f",
     *     "formats",
     *     "63f3a9fa21746d66f24853d8"
     *   ]
     */
    const urlComponents = formatUrl && formatUrl.split('/')

    /**
     * We don't wanna use the URL coming back from the initial `POST` request, because it's wrong.
     *
     * We need the `PUT` URL to be like so:
     *
     *   'https://api.test.connect.sly.swiss/upload-service-f/formats/63f3a9fa21746d66f24853d8'
     */
    const url = `${BACKEND_BASE_URL}/formats${
      method === 'put' ? `/${urlComponents[urlComponents.length - 1]}` : ''
    }`

    // debounced to limit the number of updates on the DB
    setPatchTimeoutId(
      setTimeout(async () => {
        const { data } = await axios[method](url, body)

        setFormatUrl(data._links.self.href)
      }, 500)
    )
  }

  /**
   * Example output:
   *    {
   *      "orderNoCust": "1239876",
   *      "refCustomer": "Firstname Lastname",
   *      "currencyCode": "CHF",
   *      "incoterms": "CHE-134.205.441",
   *      "shipVia": "abc@sly.ch",
   *      "paymentTerms": "8052 Zürich",
   *      "articles": [
   *        {
   *          "partNoMaxon": "velox-note-book",
   *          "partNoCust": "Velox",
   *          "qty": 10,
   *          "deliveryDate": "18.01.2022",
   *          "unitPrice": "10 / Stk.",
   *          "custAgreement": "Bestellung Total"
   *        },
   *        {
   *          "partNoMaxon": "velox-polo-shirt-black-s",
   *          "partNoCust": "VELOX",
   *          "qty": 5
   *        },
   *        {
   *          "partNoMaxon": "velox-polo-shirt-red-m",
   *          "partNoCust": "VELOX",
   *          "qty": 4
   *        },
   *        {
   *          "partNoMaxon": "velox-coffee-cup",
   *          "partNoCust": "Velox",
   *          "qty": 2
   *        }
   *      ]
   *    }
   */
  const getCartForPDF = () => {
    const itemKeys = Object.keys(customFields).filter((key) => customFields[key].type === 'item')
    const headerKeys = Object.keys(customFields).filter(
      (key) => customFields[key].type === 'header'
    )
    const leadingItemKey = getLeadingItemKey(customFields)

    /**
     * 1. build the header
     *
     * Example output:
     *
     *    {
     *      "orderNoCust": "1239876",
     *      "refCustomer": "Firstname Lastname",
     *      "currencyCode": "CHF"
     *    }
     */
    const header = headerKeys.reduce((acc, key, i) => {
      // e.g. ["1239876"]
      const selectionForKey = selection[key]

      if (!selectionForKey || !selectionForKey[0]) {
        return acc
      }

      return {
        ...acc,
        [key]: selectionForKey[0],
      }
    }, {})

    // if no "item" keys have been specified, just return the header
    if (!itemKeys || !itemKeys.length) {
      return { ...header }
    }

    /**
     * 2. build the articles
     *
     * Example output:
     *    {
     *      "partNoMaxon": "velox-note-book",
     *      "partNoCust": "Velox",
     *      "qty": 10,
     *      "deliveryDate": "18.01.2022",
     *    }
     */
    const articles = selection[leadingItemKey].map((_, i) =>
      itemKeys.reduce((acc, key) => {
        const isTypeString = isString({ key, customFields })
        const isTypeDate = isDate({ key, customFields })
        const isTypeDecimal = isDecimal({ key, customFields })

        // e.g. "am Dezember 12. 2022"
        const value = selection[key][i]

        if (
          isTypeString ||
          !value ||
          (isTypeDate && (!dateDataFormatId || !cartDateFormat)) ||
          // no need of a `cartDecimalFormat`, because we only want JSON numbers in the cart JSON
          (isTypeDecimal && !decimalDataFormatId)
        ) {
          // just output the existing value or undefined
          return { ...acc, [key]: value }
        }

        const updatedValue = isTypeDate
          ? // convert date between formats;
            // e.g. "am Dezember 12. 2022" => "2022-12-01"
            convertDate({
              value,
              fromDataFormat: dataFormats.filter((f) => f.id === dateDataFormatId)[0],
              toDataFormat: cartDateFormat,
            })
          : // parse the number;
            // e.g. "Preis 123’456,78" => 123456.78
            parseNumber({
              value,
              dataFormat: dataFormats.filter((f) => f.id === decimalDataFormatId)[0],
            })

        return { ...acc, [key]: updatedValue }
      }, {})
    )

    return { ...header, articles }
  }

  const onCartImport = async () => {
    let cartJSON = isPDF
      ? getCartForPDF()
      : {
          orderNr: data.document && data.document.orderReference,
          articles: (data.document.documentItems || []).map(
            ({ articleNumber: artNr, quantity: qty, comment: artRef }) => ({
              artNr,
              qty,
              artRef,
            })
          ),
        }

    setCart(cartJSON)

    const { orderParser } = window

    setIsCartVisible(!isCartVisible && !(orderParser && orderParser.onCartImport))

    await props.onCartImport(cartJSON)

    // TODO separate concerns
    onTutEnd()
  }

  const onFileDropped = (value, isPDF) => {
    setIsWorking(false)
    setUploadError('')
    setData(value)
    setHasUploaded(true)
    setIsPDF(isPDF)
  }

  const onFileUploading = () => {
    setUploadError('')
    setIsWorking(true)
  }

  const onFileUploadError = (code) => {
    setIsWorking(false)
    setUploadError(code)
  }

  const onAddToCart = async () => {
    setIsWorking(true)
    onFormatSave()

    await onCartImport()

    // we check the props because we wanna keep the spinner going when we're in an integration
    // and when `props.onCartImport` is not Promise-based but callback-based
    if (props.onCartImport === logCart || isPromise(props.onCartImport)) {
      setIsWorking(false)
    }
  }

  const onCancelOrder = async () => {
    setHasUploaded(false)
    setIsWorking(false)
    setIsPDF(true)
    setIsInteractive(true)
    setIsCartVisible(false)

    const firstKey = getFirstKey(customFields)
    setCurrentField(firstKey)
    setTutCurrentStep(`${firstKey}-click-location`)

    let initialSelection = getInitialSelection(customFields)
    setChoicesTexts(initialSelection)
  }

  const onTutToggle = () => setIsTutorialExpanded(!isTutorialExpanded)

  const onTutEnd = () => setTutCurrentStep('end')

  const onTutNext = ({ key, next }) => {
    if (!tutCurrentStep) {
      return
    }

    const firstKey = getFirstKey(customFields)

    if (next !== 'field') {
      setTutCurrentStep(`${key}-click-${next}`)

      return
    }

    if (!key) {
      setTutCurrentStep(`${firstKey}-click-button`)

      return
    }

    const keys = Object.keys(customFields)

    const i = keys.indexOf(key)

    if (i !== keys.length - 1) {
      // inspecting not the last key
      setTutCurrentStep(`${keys[i + 1]}-click-button`)

      return
    }

    // inspecting the last key
    setTutCurrentStep(`end`)
  }

  const onAdvancedViewToggle = () => setIsAdvancedViewShown(!isAdvancedViewShown)

  // e.g. ['needsRequiredFields', 'needsDateDateFormat']
  const getErrors = () => {
    if (!isPDF) {
      return []
    }

    const errors = []

    // 1. all items that are required must have been selected
    const needsRequiredFields = getRequiredKeys(customFields).reduce((acc, key) => {
      return acc || (isRequired(key, customFields) && !hasBeenSelected(key, selection))
    }, false)

    if (needsRequiredFields) {
      errors.push(ERRORS.NEEDS_REQUIRED_FIELDS)
    }

    // 2a. do we need a dataFormat for required decimals?
    const needsDecimalDataFormat = Object.keys(customFields).reduce((acc, key) => {
      const isFieldRequired = isRequired(key, customFields)
      const hasFieldBeenSelected = hasBeenSelected(key, selection)
      const isTypeDecimal = isDecimal({ key, customFields })

      return (
        acc || ((isFieldRequired || hasFieldBeenSelected) && isTypeDecimal && !decimalDataFormatId)
      )
    }, false)

    if (needsDecimalDataFormat) {
      errors.push(ERRORS.NEEDS_DECIMAL_DATA_FORMAT)
    }

    // 2b. do we need a dataFormat for required dates?
    const needsDateDataFormat = Object.keys(customFields).reduce((acc, key) => {
      const isFieldRequired = isRequired(key, customFields)
      const hasFieldBeenSelected = hasBeenSelected(key, selection)
      const isTypeDate = isDate({ key, customFields })

      return acc || ((isFieldRequired || hasFieldBeenSelected) && isTypeDate && !dateDataFormatId)
    }, false)

    if (needsDateDataFormat) {
      errors.push(ERRORS.NEEDS_DATE_DATA_FORMAT)
    }

    return errors
  }

  return (
    <div
      className={`upload-tool-app ${featureSwitches.isLayoutCompact ? 'uta-compact' : ''}`}
      id="upload-tool-app"
    >
      {!hasUploaded &&
        (!!props.cacheKey ? (
          <header className="uta-header" id="header" ref={HeaderRef}>
            {!uploadError && <div className="uta-spinner" />}
          </header>
        ) : (
          <header className="uta-header" id="header" ref={HeaderRef}>
            <div className="uta-drop-zone">
              <Dropzone
                {...{
                  onFileDropped,
                  onFileUploading,
                  onFileUploadError,
                  shopId,
                }}
              />
            </div>
          </header>
        ))}

      {featureSwitches.isTutorialEnabled && hasUploaded && isPDF && (
        <header className="uta-header" id="header" ref={HeaderRef}>
          <Tutorial
            {...{
              currentField,
              currentStep: tutCurrentStep,
              onNext: onTutNext,
              isExpanded: isTutorialExpanded,
              onToggle: onTutToggle,
              customFields,
              locale,
            }}
          />
        </header>
      )}

      {hasUploaded && isPDF && (
        <div className="uta-upload-adjust">
          <Buttons
            {...{
              addToCartErrors: errors,
              onFieldSelectorClick,
              onFormatChange,
              onAddToCart,
              onCancelOrder,
              selection,
              format,
              tutCurrentStep: isTutorialExpanded && tutCurrentStep,
              isLayoutCompact: featureSwitches.isLayoutCompact,
              isAdvancedViewShown,
              onAdvancedViewToggle,
              choicesTexts,
              setChoicesTexts,
              customFields,
              currentField,
              locale,
            }}
          />
          {hasUploaded && isCartVisible && (
            <pre className="uta-cart">{JSON.stringify(cart, null, 2)}</pre>
          )}
        </div>
      )}

      <div className="uta-main">
        {isWorking && !uploadError && <div className="uta-spinner"></div>}
        {uploadError && (
          <div className="uta-upload-error">
            <p>An error occurred ({uploadError}).</p>
            {uploadError === 'ocr-needed' && (
              <p>
                Please note that optical character recognition (OCR) is currently not supported.
              </p>
            )}
            {uploadError === 'wrong-format' && (
              <p>
                The document format is not supported. Please check the format of uploaded document.
              </p>
            )}
            {uploadError === 'network-error' && <p>A network error has occurred.</p>}
            {uploadError === 'not-existing-document' && (
              <p>Document for provided key: {cacheKey} does not exist.</p>
            )}
            {uploadError === 'missing-api-key' && <p>Correct API key is missing.</p>}
            {uploadError === 'limit-exceeded' && <p>Number of transactions exceeded.</p>}
            {uploadError === 'upload-file-limit-exceeded' && (
              <p>Upload file cannot have size greater than 10MB.</p>
            )}
            {uploadError === 'no-server-response' && (
              <div>
                <p>Could not connect to server, please reload or try again later.</p>
                <p>If this error persists for more than 1 hour, please contact the support.</p>
              </div>
            )}
          </div>
        )}

        {hasUploaded && isPDF && (
          <Document
            {...{
              locale,
              missingDataFormatKey,
              addToCartErrors: errors,
              format,
              dateDataFormatId,
              decimalDataFormatId,
              dataFormats,
              isInteractive,
              isLabelFeatureEnabled: featureSwitches.areLabelsEnabled,
              onFieldSelected,
              resetSelection,
              currentField,
              html: data,
              onFormatChange,
              onDateDataFormatIdChange,
              onDecimalDataFormatIdChange,
              tutCurrentStep: isTutorialExpanded && tutCurrentStep,
              headerHeight,
              isLatestFormatVersion,
              setIsFormatChecked,
              isFormatChecked,
              setChoicesTexts,
              choicesTexts,
              setSelection,
              selection,
              choicesValues,
              setChoicesValues,
              customFields,
            }}
          />
        )}

        {hasUploaded && isPDF === false && (
          <Table
            items={data.document.documentItems}
            onAddToCart={onAddToCart}
            onCancelOrder={onCancelOrder}
          />
        )}
      </div>
    </div>
  )
}

export default App
