import { requiredFields } from '../config'

export * from './BuildVU'

export const CURRENT_MOCK = 'Gueng'

export const formatMocks = {
  'Bestellung Header grösser': {
    orderNoCust: {
      relevancy: 0,
      labelPosition: '{"left":110,"top":321,"right":1156.4140625}',
      labelText: 'Binzmühlestrasse 99',
      left: 110,
      top: 344,
      right: 1212.95703125,
    },
    refCustomer: {
      relevancy: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      labelPosition: '{}',
    },
    partNoMaxon: {
      relevancy: 14,
      labelPosition: '{"left":391,"top":729,"right":919.8359375}',
      labelText: 'Artikelnummer',
      left: 391,
      top: 764,
      right: 923.87109375,
    },
    partNoCust: {
      relevancy: 13,
      labelPosition: '{}',
    },
    qty: {
      relevancy: 10,
      labelPosition: '{"left":581,"top":729,"right":781.5859375}',
      labelText: 'Menge',
      left: 627,
      top: 764,
      right: 764.25,
    },
    deliveryDate: {
      relevancy: 6,
      labelPosition: '{}',
    },
    unitPrice: {
      relevancy: 10,
      labelPosition: '{"left":658,"top":729,"right":691.44921875}',
      labelText: 'Preis/Eht',
      left: 658,
      top: 764,
      right: 700.59765625,
    },
    custAgreement: {
      relevancy: 6,
      labelPosition: '{}',
    },
  },
  'Velox Bestellung': {
    orderNoCust: {
      relevancy: 5,
      top: 392,
      left: 110,
      right: 666,
      labelPosition: '{"left": 110,"top": 392,"right": 666}',
      labelText: 'Bestellung Nr. 321321321',
      alignment: 'left', // TODO why do we need alignment here? shouldn't the one contained in the defaults object be enough?
    },
    refCustomer: {
      relevancy: 5,
      top: 486,
      left: 275,
      right: 374,
      labelPosition: '{"left": 110,"top": 486,"right": 175}',
      labelText: 'Besteller:',
      alignment: 'left',
    },
    currencyCode: {
      relevancy: 5,
      top: 604,
      left: 275,
      right: 504,
      labelPosition: '{"left": 110,"top": 604,"right": 205}',
      labelText: 'Lieferadresse:',
      alignment: 'left',
    },
    incoterms: {
      relevancy: 12,
      top: 1103,
      left: 385,
      right: 458,
      labelPosition: '{"left": 385,"top": 1121,"right": 434}',
      labelText: 'www.sly.ch',
      alignment: 'left',
    },
    shipVia: {
      relevancy: 5,
      top: 509,
      left: 275,
      right: 419,
      labelPosition: '{"left": 110,"top": 509,"right": 162}',
      labelText: 'E-Mail:',
      alignment: 'left',
    },
    paymentTerms: {
      relevancy: 0,
      labelPosition: '{"left": 660,"top": 132,"right": 627.30859375}',
      labelText: 'Binzmühlestrasse',
      left: 660,
      top: 156,
      right: 662.95703125,
    },
    partNoMaxon: {
      relevancy: 14,
      top: 717,
      left: 391,
      right: 482,
      labelPosition: '{"left": 391,"top": 682,"right": 486}',
      labelText: 'Artikelnummer',
      alignment: 'left',
    },
    partNoCust: {
      relevancy: 20,
      top: 717,
      left: 167,
      right: 262,
      labelPosition: '{"left": 167,"top": 682,"right": 247}',
      labelText: 'Bezeichnung',
      alignment: 'left',
    },
    qty: {
      relevancy: 10,
      top: 717,
      left: 627,
      right: 294,
      labelPosition: '{"left": 581,"top": 682,"right": 624}',
      labelText: 'Menge',
      alignment: 'right',
    },
    deliveryDate: {
      relevancy: 6,
      labelPosition: '{"left": 110,"top": 556,"right": 1202.0234375}',
      labelText: 'Bestelldatum:',
      left: 275,
      top: 556,
      right: 1054.4140625,
    },
    unitPrice: {
      relevancy: 7,
      top: 717,
      left: 658,
      right: 705,
      labelPosition: '{"left": 658,"top": 682,"right": 714}',
      labelText: 'Preis/Eht',
      alignment: 'left',
    },
    custAgreement: {
      relevancy: 6,
      top: 961,
      left: 118,
      right: 217,
      labelPosition: '{"left": 658,"top": 961,"right": 689}',
      labelText: 'Total',
      alignment: 'left',
    },
  },
  Fasnacht: {
    orderNr: {
      relevancy: 5,
      top: 120,
      left: 178,
      right: 676,
      labelPosition: '{"left": 87,"top": 120,"right": 739}',
      labelText: 'Belegnummer:',
      alignment: 'left',
    },
    artNr: {
      relevancy: 2,
      top: 566,
      left: 447,
      right: 386,
      labelPosition: '{"left": 347,"top": 566,"right": 468}',
      labelText: 'Ihre Artikel-Nr:',
      alignment: 'left',
    },
    qty: {
      relevancy: 3,
      top: 531,
      left: 679,
      right: 711,
      labelPosition: '{"left": 700,"top": 497,"right": 197}',
      labelText: 'LP',
      alignment: 'right',
    },
    artRef: {
      relevancy: 2,
      top: 531,
      left: 238,
      right: 636,
      labelPosition: '{"left": 238,"top": 497,"right": 631}',
      labelText: 'Art-Nr.',
      alignment: 'left',
    },
  },
  BE_306371: {
    orderNoCust: {
      left: 586,
      top: 372,
      right: 268,
      labelText: 'Beleg-Nr.',
      labelPosition: '{"left":589,"top":399,"right":262}',
    },
    refCustomer: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    currencyCode: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    incoterms: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    shipVia: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    paymentTerms: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 11,
      top: 564,
      left: 143,
      right: 679,
      labelPosition: '{"left":147,"top":524,"right":680}',
      labelText: 'Artikel-Nummer',
      alignment: 'left',
    },
    partNoMaxon: {
      left: 237,
      top: 625,
      right: 592,
      labelText: 'Ihre Artikel-Nr.',
      labelPosition: '{"top":625,"left":143,"right":680}',
      relevancy: 13,
      alignment: 'left',
    },
    qty: {
      left: 541,
      top: 562,
      right: 321,
      labelText: 'Menge',
      labelPosition: '{"left":518,"top":524,"right":350}',
      relevancy: 3,
    },
    deliveryDate: {
      relevancy: 5,
      top: 644,
      left: 354,
      right: 475,
      labelPosition: '{"top":644,"left":143,"right":601}',
      labelText: 'Lieferdatum eintreffend:',
      alignment: 'left',
    },
    unitPrice: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    custAgreement: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
  },
  BE_310139: {
    orderNoCust: {
      left: 586,
      top: 372,
      right: 268,
      labelText: 'Beleg-Nr.',
      labelPosition: '{"left":589,"top":399,"right":262}',
    },
    refCustomer: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    currencyCode: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    incoterms: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    shipVia: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    paymentTerms: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 11,
      top: 564,
      left: 143,
      right: 679,
      labelPosition: '{"left":147,"top":524,"right":680}',
      labelText: 'Artikel-Nummer',
      alignment: 'left',
    },
    partNoMaxon: {
      left: 237,
      top: 625,
      right: 592,
      labelText: 'Ihre Artikel-Nr.',
      labelPosition: '{"top":625,"left":143,"right":680}',
      relevancy: 13,
      alignment: 'left',
    },
    qty: {
      left: 541,
      top: 562,
      right: 321,
      labelText: 'Menge',
      labelPosition: '{"left":518,"top":524,"right":350}',
      relevancy: 3,
    },
    deliveryDate: {
      relevancy: 5,
      top: 644,
      left: 354,
      right: 475,
      labelPosition: '{"top":644,"left":143,"right":601}',
      labelText: 'Lieferdatum eintreffend:',
      alignment: 'left',
    },
    unitPrice: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
    custAgreement: {
      left: 0,
      top: 0,
      right: 0,
      labelText: null,
      labelPosition: '{}',
    },
  },
  Bestellung_944456_20220204: {
    orderNr: {
      relevancy: 0,
      top: 430,
      left: 225,
      right: 1125.75,
      labelPosition: '{"left": 79,"top": 378,"right": 1221.46875}',
      labelText: 'Bestellung',
    },
    artNr: {
      relevancy: 9,
      top: 808,
      left: 428,
      right: 888.19921875,
      labelPosition: '{"left": 150,"top": 808,"right": 1055.83203125}',
      labelText: 'Ihre Referenz/Artikelnummer:',
    },
    qty: {
      relevancy: 5,
      top: 883,
      left: 487,
      right: 889.7265625,
      labelPosition: '{"left": 462,"top": 658,"right": 887.5859375}',
      labelText: 'Menge',
    },
    artRef: {
      relevancy: 8,
      top: 718,
      left: 150,
      right: 1181.76171875,
      labelPosition: '{"left": 150,"top": 658,"right": 1198.734375}',
      labelText: 'Artikel',
    },
    articleLabel: {
      relevancy: 13,
      labelPosition: '{"left": 147,"top": 543,"right": 1180.91796875}',
      labelText: 'Bezeichnung',
      left: 143,
      top: 584,
      right: 1097.2421875,
    },
  },
  '84231-0000302985_4500765303': {
    orderNoCust: {
      relevancy: 0,
      labelPosition: '{"left":110,"top":321,"right":1156.4140625}',
      labelText: 'Binzmühlestrasse 99',
      left: 110,
      top: 344,
      right: 1212.95703125,
    },
    refCustomer: {
      relevancy: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      labelPosition: '{}',
    },
    partNoMaxon: {
      relevancy: 14,
      labelPosition: '{"left":391,"top":729,"right":919.8359375}',
      labelText: 'Artikelnummer',
      left: 391,
      top: 764,
      right: 923.87109375,
    },
    partNoCust: {
      relevancy: 13,
      labelPosition: '{}',
    },
    qty: {
      relevancy: 10,
      labelPosition: '{"left":581,"top":729,"right":781.5859375}',
      labelText: 'Menge',
      left: 627,
      top: 764,
      right: 764.25,
    },
    deliveryDate: {
      relevancy: 6,
      labelPosition: '{}',
    },
    unitPrice: {
      relevancy: 10,
      labelPosition: '{"left":658,"top":729,"right":691.44921875}',
      labelText: 'Preis/Eht',
      left: 658,
      top: 764,
      right: 700.59765625,
    },
    custAgreement: {
      relevancy: 6,
      labelPosition: '{}',
    },
  },
  '84231-20230223_4500773830_BESI_rev1': {
    orderNoCust: {
      relevancy: 0,
      labelPosition: '{"left":110,"top":321,"right":1156.4140625}',
      labelText: 'Binzmühlestrasse 99',
      left: 110,
      top: 344,
      right: 1212.95703125,
    },
    refCustomer: {
      relevancy: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      labelPosition: '{}',
    },
    partNoMaxon: {
      relevancy: 14,
      labelPosition: '{"left":391,"top":729,"right":919.8359375}',
      labelText: 'Artikelnummer',
      left: 391,
      top: 764,
      right: 923.87109375,
    },
    partNoCust: {
      relevancy: 13,
      labelPosition: '{}',
    },
    qty: {
      relevancy: 10,
      labelPosition: '{"left":581,"top":729,"right":781.5859375}',
      labelText: 'Menge',
      left: 627,
      top: 764,
      right: 764.25,
    },
    deliveryDate: {
      relevancy: 6,
      labelPosition: '{}',
    },
    unitPrice: {
      relevancy: 10,
      labelPosition: '{"left":658,"top":729,"right":691.44921875}',
      labelText: 'Preis/Eht',
      left: 658,
      top: 764,
      right: 700.59765625,
    },
    custAgreement: {
      relevancy: 6,
      labelPosition: '{}',
    },
  },
  2765: {
    orderNoCust: {
      relevancy: 5,
      top: 396,
      left: 104,
      right: 569,
      labelPosition: '{ "left": 104, "top": 396, "right": 569 }',
      labelText: 'Bestellung 001-084046',
      alignment: 'left',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 7,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoMaxon: {
      relevancy: 9,
      top: 774,
      left: 139,
      right: 620,
      labelPosition: '{ "left": 139, "top": 774, "right": 620 }',
      labelText: 'Ihre Artikelnummer 205635',
      alignment: 'left',
    },
    qty: {
      relevancy: 10,
      top: 674,
      left: 382,
      right: 479,
      labelPosition: '{ "left": 390, "top": 621, "right": 477 }',
      labelText: 'Menge',
      alignment: 'right',
    },
    deliveryDate: {
      relevancy: 6,
      left: 702,
      top: 691,
      right: 531,
      labelText: 'Liefertermin:',
      labelPosition: '{ "top": 691, "left": 557, "right": 273 }',
      alignment: 'left',
    },
    unitPrice: {
      relevancy: 10,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  '2765b': {
    orderNoCust: {
      relevancy: 5,
      top: 396,
      left: 104,
      right: 569,
      labelPosition: '{ "left": 104, "top": 396, "right": 569 }',
      labelText: 'Bestellung 001-084046',
      alignment: 'left',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 7,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoMaxon: {
      relevancy: 9,
      top: 774,
      left: 139,
      right: 620,
      labelPosition: '{ "left": 139, "top": 774, "right": 620 }',
      labelText: 'Ihre Artikelnummer 205635',
      alignment: 'left',
    },
    qty: {
      relevancy: 10,
      top: 674,
      left: 382,
      right: 479,
      labelPosition: '{ "left": 390, "top": 621, "right": 477 }',
      labelText: 'Menge',
      alignment: 'right',
    },
    deliveryDate: {
      relevancy: 6,
      left: 702,
      top: 691,
      right: 531,
      labelText: 'Liefertermin:',
      labelPosition: '{ "top": 691, "left": 557, "right": 273 }',
      alignment: 'left',
    },
    unitPrice: {
      relevancy: 10,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  '53835-1': {
    orderNoCust: {
      relevancy: 5,
      left: 618,
      top: 304,
      right: 198,
      labelText: 'B E S T E L L U N G',
      labelPosition: '{"left":426,"top":304,"right":330}',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 5,
      left: 788,
      top: 674,
      right: 92,
      labelText: 'Währung',
      labelPosition: '{"left":761,"top":621,"right":92}',
    },
    incoterms: {
      relevancy: 5,
      left: 226,
      top: 3233,
      right: 404,
      labelText: 'Lieferbedingungen',
      labelPosition: '{"top":3233,"left":64,"right":751}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 9,
      left: 127,
      top: 1666,
      right: 610,
      labelText: 'Unsere Artikelnr.: 21.117.07',
      labelPosition: '{"top":1666,"left":127,"right":625}',
    },
    partNoMaxon: {
      relevancy: 8,
      left: 127,
      top: 2224,
      right: 671,
      labelText: 'Artikelnr.: 696837',
      labelPosition: '{"top":2224,"left":127,"right":678}',
    },
    qty: {
      relevancy: 3,
      left: 486,
      top: 1666,
      right: 374,
      labelText: '50 Stck',
      labelPosition: '{"top":1666,"left":486,"right":378}',
    },
    deliveryDate: {
      relevancy: 6,
      left: 675,
      top: 691,
      right: 169,
      labelText: 'Anliefertermin',
      labelPosition: '{"left":557,"top":691,"right":273}',
    },
    unitPrice: {
      relevancy: 3,
      left: 671,
      top: 1666,
      right: 200,
      labelText: 'EUR/ Stck',
      labelPosition: '{"left":643,"top":1683,"right":199}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  '53835-2-Bestellung APOB11427': {
    orderNoCust: {
      relevancy: 5,
      left: 618,
      top: 304,
      right: 198,
      labelText: 'B E S T E L L U N G',
      labelPosition: '{"left":426,"top":304,"right":330}',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 5,
      left: 788,
      top: 674,
      right: 92,
      labelText: 'Währung',
      labelPosition: '{"left":761,"top":621,"right":92}',
    },
    incoterms: {
      relevancy: 5,
      left: 226,
      top: 3233,
      right: 404,
      labelText: 'Lieferbedingungen',
      labelPosition: '{"top":3233,"left":64,"right":751}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 9,
      left: 127,
      top: 1666,
      right: 610,
      labelText: 'Unsere Artikelnr.: 21.117.07',
      labelPosition: '{"top":1666,"left":127,"right":625}',
    },
    partNoMaxon: {
      relevancy: 8,
      left: 127,
      top: 2224,
      right: 671,
      labelText: 'Artikelnr.: 696837',
      labelPosition: '{"top":2224,"left":127,"right":678}',
    },
    qty: {
      relevancy: 3,
      left: 486,
      top: 1666,
      right: 374,
      labelText: '50 Stck',
      labelPosition: '{"top":1666,"left":486,"right":378}',
    },
    deliveryDate: {
      relevancy: 6,
      left: 675,
      top: 691,
      right: 169,
      labelText: 'Anliefertermin',
      labelPosition: '{"left":557,"top":691,"right":273}',
    },
    unitPrice: {
      relevancy: 3,
      left: 671,
      top: 1666,
      right: 200,
      labelText: 'EUR/ Stck',
      labelPosition: '{"left":643,"top":1683,"right":199}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  146: {
    orderNoCust: {
      relevancy: 5,
      left: 247,
      top: 411,
      right: 600,
      labelText: 'Nummer',
      labelPosition: '{"left":87,"top":411,"right":766}',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 11,
      left: 102,
      top: 582,
      right: 736,
      labelText: 'Artikelnummer',
      labelPosition: '{"left":87,"top":510,"right":730}',
    },
    partNoMaxon: {
      relevancy: 8,
      left: 87,
      top: 543,
      right: 720,
      labelText: 'Artikelnummer',
      labelPosition: '{"left":87,"top":510,"right":730}',
    },
    qty: {
      relevancy: 3,
      left: 553,
      top: 543,
      right: 318,
      labelText: 'Menge',
      labelPosition: '{"left":546,"top":510,"right":317}',
    },
    deliveryDate: {
      relevancy: 6,
      left: 434,
      top: 543,
      right: 402,
      labelText: 'Lieferung',
      labelPosition: '{"left":434,"top":510,"right":414}',
    },
    unitPrice: {
      relevancy: 3,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  '146b': {
    orderNoCust: {
      relevancy: 5,
      left: 247,
      top: 411,
      right: 600,
      labelText: 'Nummer',
      labelPosition: '{"left":87,"top":411,"right":766}',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 11,
      left: 102,
      top: 582,
      right: 736,
      labelText: 'Artikelnummer',
      labelPosition: '{"left":87,"top":510,"right":730}',
    },
    partNoMaxon: {
      relevancy: 8,
      left: 87,
      top: 543,
      right: 720,
      labelText: 'Artikelnummer',
      labelPosition: '{"left":87,"top":510,"right":730}',
    },
    qty: {
      relevancy: 3,
      left: 553,
      top: 543,
      right: 318,
      labelText: 'Menge',
      labelPosition: '{"left":546,"top":510,"right":317}',
    },
    deliveryDate: {
      relevancy: 6,
      left: 434,
      top: 543,
      right: 402,
      labelText: 'Lieferung',
      labelPosition: '{"left":434,"top":510,"right":414}',
    },
    unitPrice: {
      relevancy: 3,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  2192: {
    orderNoCust: {
      relevancy: 5,
      left: 518,
      top: 124,
      right: 327,
      labelText: 'Belegnummer',
      labelPosition: '{ "left": 518, "top": 107, "right": 306 }',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 11,
      left: 106,
      top: 515,
      right: 694,
      labelText: 'Artikel Nr.: 129750',
      labelPosition: '{ "left": 106, "top": 515, "right": 694 }',
    },
    partNoMaxon: {
      relevancy: 8,
      top: 528,
      left: 106,
      right: 645,
      labelPosition: '{ "left": 106, "top": 528, "right": 645 }',
      labelText: 'Lieferantenkatalog-Nr.: 693230',
      alignment: 'left',
    },
    qty: {
      relevancy: 3,
      left: 571,
      top: 498,
      right: 293,
      labelText: 'Menge',
      labelPosition: '{ "left": 574, "top": 456, "right": 293 }',
    },
    deliveryDate: {
      relevancy: 6,
      left: 477,
      top: 498,
      right: 375,
      labelText: 'Lieferdatum Eingang',
      labelPosition: '{ "left": 409, "top": 456, "right": 376 }',
    },
    unitPrice: {
      relevancy: 3,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  '2192b': {
    orderNoCust: {
      relevancy: 5,
      left: 518,
      top: 124,
      right: 327,
      labelText: 'Belegnummer',
      labelPosition: '{ "left": 518, "top": 107, "right": 306 }',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 5,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 11,
      left: 106,
      top: 515,
      right: 694,
      labelText: 'Artikel Nr.: 129750',
      labelPosition: '{ "left": 106, "top": 515, "right": 694 }',
    },
    partNoMaxon: {
      relevancy: 8,
      top: 528,
      left: 106,
      right: 645,
      labelPosition: '{ "left": 106, "top": 528, "right": 645 }',
      labelText: 'Lieferantenkatalog-Nr.: 693230',
      alignment: 'left',
    },
    qty: {
      relevancy: 3,
      left: 571,
      top: 498,
      right: 293,
      labelText: 'Menge',
      labelPosition: '{ "left": 574, "top": 456, "right": 293 }',
    },
    deliveryDate: {
      relevancy: 6,
      left: 477,
      top: 498,
      right: 375,
      labelText: 'Lieferdatum Eingang',
      labelPosition: '{ "left": 409, "top": 456, "right": 376 }',
    },
    unitPrice: {
      relevancy: 3,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  104353: {
    orderNoCust: {
      relevancy: 5,
      left: 208,
      top: 190,
      right: 582,
      labelText: 'PO number/Group:',
      labelPosition: '{"left":65,"top":190,"right":711}',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 7,
      left: 130,
      top: 763,
      right: 677,
      labelText: 'Material',
      labelPosition: '{"left":130,"top":717,"right":717}',
    },
    partNoMaxon: {
      relevancy: 13,
      left: 65,
      top: 800,
      right: 632,
      labelText: 'Document: E41350600+2+000',
      labelPosition: '{"left":65,"top":800,"right":632}',
    },
    qty: {
      relevancy: 9,
      left: 256,
      top: 781,
      right: 623,
      labelText: 'Order qty.',
      labelPosition: '{"left":260,"top":735,"right":572}',
    },
    deliveryDate: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    unitPrice: {
      relevancy: 10,
      left: 429,
      top: 781,
      right: 417,
      labelText: 'Price per unit',
      labelPosition: '{"left":455,"top":735,"right":353}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  111536: {
    orderNoCust: {
      relevancy: 5,
      left: 1005,
      top: 80,
      right: 167,
      labelText: 'PURCHASE ORDER NO.',
      labelPosition: '{"left":1005,"top":65,"right":168}',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 7,
      left: 76,
      top: 404,
      right: 1045,
      labelText: 'All prices and amounts on this order are expressed in: Swiss Franc',
      labelPosition: '{"left":76,"top":379,"right":766}',
    },
    partNoMaxon: {
      relevancy: 13,
      left: 129,
      top: 437,
      right: 1107,
      labelText: 'MPN#:',
      labelPosition: '{"left":76,"top":437,"right":1164}',
    },
    qty: {
      relevancy: 9,
      left: 803,
      top: 404,
      right: 463,
      labelText: 'EACH',
      labelPosition: '{"left":831,"top":404,"right":413}',
    },
    deliveryDate: {
      relevancy: 6,
      left: 648,
      top: 404,
      right: 570,
      labelText: 'Dock Date',
      labelPosition: '{"left":654,"top":390,"right":576}',
    },
    unitPrice: {
      relevancy: 10,
      left: 1012,
      top: 404,
      right: 228,
      labelText: 'EACH',
      labelPosition: '{"left":831,"top":404,"right":413}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  '84231b-20230223_4500773830_BESI_rev1': {
    orderNoCust: {
      relevancy: 5,
      left: 1005,
      top: 80,
      right: 167,
      labelText: 'PURCHASE ORDER NO.',
      labelPosition: '{"left":1005,"top":65,"right":168}',
    },
    refCustomer: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    currencyCode: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    incoterms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    shipVia: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    paymentTerms: {
      relevancy: 0,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
    partNoCust: {
      relevancy: 7,
      left: 76,
      top: 404,
      right: 1045,
      labelText: 'All prices and amounts on this order are expressed in: Swiss Franc',
      labelPosition: '{"left":76,"top":379,"right":766}',
    },
    partNoMaxon: {
      relevancy: 13,
      left: 129,
      top: 437,
      right: 1107,
      labelText: 'MPN#:',
      labelPosition: '{"left":76,"top":437,"right":1164}',
    },
    qty: {
      relevancy: 9,
      left: 803,
      top: 404,
      right: 463,
      labelText: 'EACH',
      labelPosition: '{"left":831,"top":404,"right":413}',
    },
    deliveryDate: {
      relevancy: 6,
      left: 648,
      top: 404,
      right: 570,
      labelText: 'Dock Date',
      labelPosition: '{"left":654,"top":390,"right":576}',
    },
    unitPrice: {
      relevancy: 10,
      left: 1012,
      top: 404,
      right: 228,
      labelText: 'EACH',
      labelPosition: '{"left":831,"top":404,"right":413}',
    },
    custAgreement: {
      relevancy: 6,
      left: 0,
      top: 0,
      right: 0,
      labelPosition: '{}',
    },
  },
  Gueng: {
    orderNr: {
      relevancy: 5,
      top: 120,
      left: 178,
      right: 676,
      labelPosition: '{"left": 87,"top": 120,"right": 739}',
      labelText: 'Belegnummer:',
      alignment: 'left',
    },
    artNr: {
      relevancy: 2,
      top: 566,
      left: 447,
      right: 386,
      labelPosition: '{"left": 347,"top": 566,"right": 468}',
      labelText: 'Ihre Artikel-Nr:',
      alignment: 'left',
    },
    qty: {
      relevancy: 3,
      top: 531,
      left: 679,
      right: 711,
      labelPosition: '{"left": 700,"top": 497,"right": 197}',
      labelText: 'LP',
      alignment: 'right',
    },
    artRef: {
      relevancy: 2,
      top: 531,
      left: 238,
      right: 636,
      labelPosition: '{"left": 238,"top": 497,"right": 631}',
      labelText: 'Art-Nr.',
      alignment: 'left',
    },
  },
}

export const fieldsMocks = {
  'Bestellung Header grösser': {
    orderNoCust: {
      type: 'header',
      labels: {
        default: 'PO Number',
        de: 'PO Nummer',
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    refCustomer: {
      type: 'header',
      labels: {
        default: 'Cust. Reference',
        en: 'Cust. Reference',
        de: 'Kunden Referenz',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    currencyCode: {
      type: 'header',
      labels: {
        default: 'Currency',
        en: 'Currency',
        de: 'Währung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    incoterms: {
      type: 'header',
      labels: {
        default: 'Incoterms',
        en: 'Incoterms',
        de: 'INCO Bedinungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    shipVia: {
      type: 'header',
      labels: {
        default: 'Ship-Via',
        en: 'Ship-Via',
        de: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    paymentTerms: {
      type: 'header',
      labels: {
        default: 'Payment Terms',
        en: 'Payment Terms',
        de: 'Zahlungsbedinnungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        default: 'Maxon Part No.',
        en: 'Maxon Part No.',
        de: 'Maxon Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    partNoCust: {
      type: 'item',
      labels: {
        default: 'Cust. Part No.',
        en: 'Cust. Part No.',
        de: 'Kunden Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    qty: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Qty',
        en: 'Qty',
        de: 'Menge',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'right',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        isUnique: false,
        isQuantityFormat: true,
      },
    },
    deliveryDate: {
      type: 'item',
      dataType: 'DATE',
      labels: {
        default: 'Delivery Date',
        en: 'Delivery Date',
        de: 'Lieferdatum',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    unitPrice: {
      type: 'item',
      labels: {
        default: 'Unit Price',
        en: 'Unit Price',
        de: 'Stückpreis',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    custAgreement: {
      type: 'item',
      labels: {
        default: 'Cust. Agreement',
        en: 'Cust. Agreement',
        de: 'Kundenvereinbarung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
  },
  'Velox Bestellung': {
    orderNoCust: {
      type: 'header',
      labels: {
        default: 'PO Number',
        de: 'PO Nummer',
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    refCustomer: {
      type: 'header',
      labels: {
        default: 'Cust. Reference',
        en: 'Cust. Reference',
        de: 'Kunden Referenz',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    currencyCode: {
      type: 'header',
      labels: {
        default: 'Currency',
        en: 'Currency',
        de: 'Währung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    incoterms: {
      type: 'header',
      labels: {
        default: 'Incoterms',
        en: 'Incoterms',
        de: 'INCO Bedinungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    shipVia: {
      type: 'header',
      labels: {
        default: 'Ship-Via',
        en: 'Ship-Via',
        de: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    paymentTerms: {
      type: 'header',
      labels: {
        default: 'Payment Terms',
        en: 'Payment Terms',
        de: 'Zahlungsbedinnungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        default: 'Maxon Part No.',
        en: 'Maxon Part No.',
        de: 'Maxon Teil Nr.',
      },
      regex: null,
      required: false,
      isLeading: true,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    partNoCust: {
      type: 'item',
      labels: {
        default: 'Cust. Part No.',
        en: 'Cust. Part No.',
        de: 'Kunden Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    qty: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Qty',
        en: 'Qty',
        de: 'Menge',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'right',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        isUnique: false,
        isQuantityFormat: true,
      },
    },
    deliveryDate: {
      type: 'item',
      dataType: 'DATE',
      labels: {
        default: 'Delivery Date',
        en: 'Delivery Date',
        de: 'Lieferdatum',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    unitPrice: {
      type: 'item',
      labels: {
        default: 'Unit Price',
        en: 'Unit Price',
        de: 'Stückpreis',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    custAgreement: {
      type: 'item',
      labels: {
        default: 'Cust. Agreement',
        en: 'Cust. Agreement',
        de: 'Kundenvereinbarung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
  },
  Fasnacht: requiredFields,
  BE_306371: {
    orderNoCust: {
      type: 'header',
      labels: {
        default: 'PO Number',
        de: 'PO Nummer',
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    refCustomer: {
      type: 'header',
      labels: {
        default: 'Cust. Reference',
        en: 'Cust. Reference',
        de: 'Kunden Referenz',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    currencyCode: {
      type: 'header',
      labels: {
        default: 'Currency',
        en: 'Currency',
        de: 'Währung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    incoterms: {
      type: 'header',
      labels: {
        default: 'Incoterms',
        en: 'Incoterms',
        de: 'INCO Bedinungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    shipVia: {
      type: 'header',
      labels: {
        default: 'Ship-Via',
        en: 'Ship-Via',
        de: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    paymentTerms: {
      type: 'header',
      labels: {
        default: 'Payment Terms',
        en: 'Payment Terms',
        de: 'Zahlungsbedinnungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        default: 'Maxon Part No.',
        en: 'Maxon Part No.',
        de: 'Maxon Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    partNoCust: {
      type: 'item',
      labels: {
        default: 'Cust. Part No.',
        en: 'Cust. Part No.',
        de: 'Kunden Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    qty: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Qty',
        en: 'Qty',
        de: 'Menge',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'right',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        isUnique: false,
        isQuantityFormat: true,
      },
    },
    deliveryDate: {
      type: 'item',
      dataType: 'DATE',
      labels: {
        default: 'Delivery Date',
        en: 'Delivery Date',
        de: 'Lieferdatum',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    unitPrice: {
      type: 'item',
      labels: {
        default: 'Unit Price',
        en: 'Unit Price',
        de: 'Stückpreis',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    custAgreement: {
      type: 'item',
      labels: {
        default: 'Cust. Agreement',
        en: 'Cust. Agreement',
        de: 'Kundenvereinbarung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
  },
  BE_310139: {
    orderNoCust: {
      type: 'header',
      labels: {
        default: 'PO Number',
        de: 'PO Nummer',
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    refCustomer: {
      type: 'header',
      labels: {
        default: 'Cust. Reference',
        en: 'Cust. Reference',
        de: 'Kunden Referenz',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    currencyCode: {
      type: 'header',
      labels: {
        default: 'Currency',
        en: 'Currency',
        de: 'Währung',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    incoterms: {
      type: 'header',
      labels: {
        default: 'Incoterms',
        en: 'Incoterms',
        de: 'INCO Bedinungen',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    shipVia: {
      type: 'header',
      labels: {
        default: 'Ship-Via',
        en: 'Ship-Via',
        de: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    paymentTerms: {
      type: 'header',
      labels: {
        default: 'Payment Terms',
        en: 'Payment Terms',
        de: 'Zahlungsbedinnungen',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    partNoCust: {
      type: 'item',
      labels: {
        default: 'Cust. Part No.',
        en: 'Cust. Part No.',
        de: 'Kunden Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        default: 'Maxon Part No.',
        en: 'Maxon Part No.',
        de: 'Maxon Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    qty: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Qty',
        en: 'Qty',
        de: 'Menge',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
    },
    deliveryDate: {
      type: 'item',
      dataType: 'DATE',
      labels: {
        default: 'Delivery Date',
        en: 'Delivery Date',
        de: 'Lieferdatum',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    unitPrice: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Unit Price',
        en: 'Unit Price',
        de: 'Stückpreis',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    custAgreement: {
      type: 'item',
      labels: {
        default: 'Cust. Agreement',
        en: 'Cust. Agreement',
        de: 'Kundenvereinbarung',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
    },
  },
  Bestellung_944456_20220204: {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  '84231-0000302985_4500765303': {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  '84231-20230223_4500773830_BESI_rev1': {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  2765: {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  '2765b': {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  '53835-1': {
    orderNoCust: {
      type: 'header',
      labels: {
        default: 'PO Number',
        de: 'PO Nummer',
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    refCustomer: {
      type: 'header',
      labels: {
        default: 'Cust. Reference',
        en: 'Cust. Reference',
        de: 'Kunden Referenz',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    currencyCode: {
      type: 'header',
      labels: {
        default: 'Currency',
        en: 'Currency',
        de: 'Währung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    incoterms: {
      type: 'header',
      labels: {
        default: 'Incoterms',
        en: 'Incoterms',
        de: 'INCO Bedinungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    shipVia: {
      type: 'header',
      labels: {
        default: 'Ship-Via',
        en: 'Ship-Via',
        de: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    paymentTerms: {
      type: 'header',
      labels: {
        default: 'Payment Terms',
        en: 'Payment Terms',
        de: 'Zahlungsbedinnungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        default: 'Maxon Part No.',
        en: 'Maxon Part No.',
        de: 'Maxon Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    partNoCust: {
      type: 'item',
      labels: {
        default: 'Cust. Part No.',
        en: 'Cust. Part No.',
        de: 'Kunden Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    qty: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Qty',
        en: 'Qty',
        de: 'Menge',
      },
      regex: null,
      required: true,
      isLeading: true,
      defaults: {
        alignment: 'right',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        isUnique: false,
        isQuantityFormat: true,
      },
    },
    deliveryDate: {
      type: 'item',
      dataType: 'DATE',
      labels: {
        default: 'Delivery Date',
        en: 'Delivery Date',
        de: 'Lieferdatum',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    unitPrice: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Unit Price',
        en: 'Unit Price',
        de: 'Stückpreis',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    custAgreement: {
      type: 'item',
      labels: {
        default: 'Cust. Agreement',
        en: 'Cust. Agreement',
        de: 'Kundenvereinbarung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
  },
  '53835-2-Bestellung APOB11427': {
    orderNoCust: {
      type: 'header',
      labels: {
        default: 'PO Number',
        de: 'PO Nummer',
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    refCustomer: {
      type: 'header',
      labels: {
        default: 'Cust. Reference',
        en: 'Cust. Reference',
        de: 'Kunden Referenz',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    currencyCode: {
      type: 'header',
      labels: {
        default: 'Currency',
        en: 'Currency',
        de: 'Währung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    incoterms: {
      type: 'header',
      labels: {
        default: 'Incoterms',
        en: 'Incoterms',
        de: 'INCO Bedinungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    shipVia: {
      type: 'header',
      labels: {
        default: 'Ship-Via',
        en: 'Ship-Via',
        de: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    paymentTerms: {
      type: 'header',
      labels: {
        default: 'Payment Terms',
        en: 'Payment Terms',
        de: 'Zahlungsbedinnungen',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: true,
        isQuantityFormat: false,
      },
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        default: 'Maxon Part No.',
        en: 'Maxon Part No.',
        de: 'Maxon Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    partNoCust: {
      type: 'item',
      labels: {
        default: 'Cust. Part No.',
        en: 'Cust. Part No.',
        de: 'Kunden Teil Nr.',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    qty: {
      type: 'item',
      dataType: 'DECIMAL',
      labels: {
        default: 'Qty',
        en: 'Qty',
        de: 'Menge',
      },
      regex: null,
      required: true,
      isLeading: true,
      defaults: {
        alignment: 'right',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        isUnique: false,
        isQuantityFormat: true,
      },
    },
    deliveryDate: {
      type: 'item',
      dataType: 'DATE',
      labels: {
        default: 'Delivery Date',
        en: 'Delivery Date',
        de: 'Lieferdatum',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    unitPrice: {
      type: 'item',
      labels: {
        default: 'Unit Price',
        en: 'Unit Price',
        de: 'Stückpreis',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
    custAgreement: {
      type: 'item',
      labels: {
        default: 'Cust. Agreement',
        en: 'Cust. Agreement',
        de: 'Kundenvereinbarung',
      },
      regex: null,
      required: false,
      defaults: {
        alignment: 'left',
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        isUnique: false,
        isQuantityFormat: false,
      },
    },
  },
  146: {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  '146b': {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  2192: {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  '2192b': {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  104353: {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  111536: {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  '84231b-20230223_4500773830_BESI_rev1': {
    orderNoCust: {
      type: 'header',
      labels: {
        en: 'PO Number',
      },
      regex: null,
      required: true,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 5,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    refCustomer: {
      type: 'header',
      labels: {
        en: 'Cust. Reference',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    currencyCode: {
      type: 'header',
      labels: {
        en: 'Currency',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    incoterms: {
      type: 'header',
      labels: {
        en: 'Incoterms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    shipVia: {
      type: 'header',
      labels: {
        en: 'Ship-Via',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    paymentTerms: {
      type: 'header',
      labels: {
        en: 'Payment Terms',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 0,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'left',
        isUnique: true,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoCust: {
      type: 'item',
      labels: {
        en: 'Cust. Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    partNoMaxon: {
      type: 'item',
      labels: {
        en: 'Maxon Part No.',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 13,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
    qty: {
      type: 'item',
      labels: {
        en: 'Qty',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 1,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: true,
      },
      dataType: 'DECIMAL',
      isLeading: true,
    },
    deliveryDate: {
      type: 'item',
      labels: {
        en: 'Delivery Date',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 6,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DATE',
      isLeading: false,
    },
    unitPrice: {
      type: 'item',
      labels: {
        en: 'Unit Price',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 10,
        mustHaveSameLength: false,
        minLength: 3,
        alignment: 'right',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: 'DECIMAL',
      isLeading: false,
    },
    custAgreement: {
      type: 'header',
      labels: {
        en: 'Cust. Agreement',
      },
      regex: null,
      required: false,
      defaults: {
        relevancy: 6,
        mustHaveSameLength: false,
        minLength: 4,
        alignment: 'left',
        isUnique: false,
        isQuantityFormat: false,
      },
      dataType: null,
      isLeading: false,
    },
  },
  Gueng: requiredFields,
}

export const choicesMocks = {
  'Velox Bestellung': {
    orderNoCust: [false, false, true],
    refCustomer: [true, true],
    currencyCode: [true, true, true],
    incoterms: [true],
    shipVia: [true],
    paymentTerms: [true, true],
    partNoMaxon: [true],
    partNoCust: [true, false, true],
    qty: [],
    deliveryDate: [],
    unitPrice: [true, true, true],
    custAgreement: [true, true],
  },
  Fasnacht: {
    orderNr: [true],
    artNr: [true],
    qty: [],
    artRef: [true],
  },
  BE_306371: {
    orderNr: [true],
    artNr: [true],
    qty: [],
    artRef: [true],
    articleLabel: [true, true],
  },
  BE_310139: {
    orderNoCust: [],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [],
    partNoMaxon: [],
    qty: [],
    deliveryDate: [],
    unitPrice: [],
    custAgreement: [],
  },
  Bestellung_944456_20220204: {
    orderNr: [true],
    artNr: [true],
    qty: [],
    artRef: [true],
    articleLabel: [true, true],
  },
  '84231-0000302985_4500765303': {
    orderNoCust: [false, false, true],
    refCustomer: [true, true],
    currencyCode: [true, true, true],
    incoterms: [true],
    shipVia: [true],
    paymentTerms: [true, true],
    partNoMaxon: [true],
    partNoCust: [true, false, true],
    qty: [],
    deliveryDate: [],
    unitPrice: [true, true, true],
    custAgreement: [true, true],
  },
  '84231-20230223_4500773830_BESI_rev1': {
    orderNoCust: [false, false, true],
    refCustomer: [true, true],
    currencyCode: [true, true, true],
    incoterms: [true],
    shipVia: [true],
    paymentTerms: [true, true],
    partNoMaxon: [true],
    partNoCust: [true, false, true],
    qty: [],
    deliveryDate: [],
    unitPrice: [true, true, true],
    custAgreement: [true, true],
  },
  2765: {
    orderNoCust: [false, true],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [true, true],
    partNoMaxon: [false, false, true],
    custAgreement: [],
  },
  '2765b': {
    orderNoCust: [false, true],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [true, true],
    partNoMaxon: [false, false, true],
    custAgreement: [],
  },
  '53835-1': {
    orderNoCust: [true, false],
    refCustomer: [],
    currencyCode: [true, true, true],
    incoterms: [true, true],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [false, false, true],
    partNoMaxon: [false, true],
    custAgreement: [],
  },
  '53835-2-Bestellung APOB11427': {
    orderNoCust: [true, false],
    refCustomer: [],
    currencyCode: [true, true, true],
    incoterms: [true, true],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [false, false, true],
    partNoMaxon: [false, true],
    custAgreement: [],
  },
  146: {
    orderNoCust: [true, false],
    refCustomer: [],
    currencyCode: [true, true, true],
    incoterms: [true, true],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [true, true],
    partNoMaxon: [true, true],
    custAgreement: [],
  },
  '146b': {
    orderNoCust: [true, false],
    refCustomer: [],
    currencyCode: [true, true, true],
    incoterms: [true, true],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [true, true],
    partNoMaxon: [true, true],
    custAgreement: [],
  },
  2192: {
    orderNoCust: [],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [false, false, true],
    partNoMaxon: [false, true],
    custAgreement: [],
  },
  '2192b': {
    orderNoCust: [],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [false, false, true],
    partNoMaxon: [false, true],
    custAgreement: [],
  },
  104353: {
    orderNoCust: [true, false],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [true, true],
    partNoMaxon: [false, true],
    custAgreement: [],
  },
  111536: {
    orderNoCust: [true, false],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [false, false, true],
    partNoMaxon: [false, true],
    custAgreement: [],
  },
  '84231b-20230223_4500773830_BESI_rev1': {
    orderNoCust: [true, false],
    refCustomer: [],
    currencyCode: [],
    incoterms: [],
    shipVia: [],
    paymentTerms: [],
    partNoCust: [false, false, true],
    partNoMaxon: [false, true],
    custAgreement: [],
  },
  Gueng: {
    orderNr: [true],
    artNr: [true],
    qty: [],
    artRef: [true],
  },
}

export const dataFormatsMock = [
  {
    id: 'yyyy.MM.dd',
    dataType: 'DATE',
    regex: '\\b20[0-9]{2}\\.(0?[1-9]|1[0-2])\\.(0?[1-9]|[1-2][0-9]|30|31)\\b',
    mask: 'yyyy.MM.dd',
    locale: null,
    displayName: {
      de: '(z.B 2023.11.17)',
      en: '(e.g. 2023.11.17)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/yyyy.MM.dd',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/yyyy.MM.dd',
      },
    },
  },
  {
    id: 'dd.MM.yyyy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|[12][0-9]|30|31)\\.(0?[1-9]|1[0-2])\\.20[0-9]{2}',
    mask: 'dd.MM.yyyy',
    locale: null,
    displayName: {
      de: '(z.B 17.11.2023)',
      en: '(e.g. 17.11.2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.MM.yyyy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.MM.yyyy',
      },
    },
  },
  {
    id: 'yyyy-MM-dd',
    dataType: 'DATE',
    regex: '\\d{4}-\\d{1,2}-\\d{1,2}',
    mask: 'yyyy-MM-dd',
    locale: null,
    displayName: {
      de: '(z.B. 2022-12-31)',
      en: '(e.g. 2022-12-31)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/yyyy-MM-dd',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/yyyy-MM-dd',
      },
    },
  },
  {
    id: 'dd-MM-yyyy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|[12][0-9]|30|31)-(0?[1-9]|1[0-2])-20[0-9]{2}',
    mask: 'dd-MM-yyyy',
    locale: null,
    displayName: {
      de: '(z.B. 17-11-2023)',
      en: '(e.g. 17-11-2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MM-yyyy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MM-yyyy',
      },
    },
  },
  {
    id: 'dd. MMMM yyyy de',
    dataType: 'DATE',
    regex:
      '\\b(0?[1-9]|[12][0-9]|30|31)\\. (Januar|Februar|März|April|Mai|Juni|Juli|August|September|Oktober|November|Dezember) 20[0-9]{2}',
    mask: 'dd. MMMM yyyy',
    locale: 'de',
    displayName: {
      de: '(z.B. 17. Januar 2023)',
      en: '(e.g. 17. Januar 2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.%20MMMM%20yyyy%20de',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.%20MMMM%20yyyy%20de',
      },
    },
  },
  {
    id: 'dd. MMM yyyy de',
    dataType: 'DATE',
    regex:
      '\\b(0?[1-9]|[12][0-9]|30|31)\\. (Jan\\.?|Feb\\.?|März|Apr\\.?|Mai|Juni|Juli|Aug\\.?|Sep\\.?|Okt\\.?|Nov\\.?|Dez\\.?) 20[0-9]{2}',
    mask: 'dd. MMM yyyy',
    locale: 'de',
    displayName: {
      de: '(z.B 17. Jan. 2023)',
      en: '(e.g. 17. Jan. 2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.%20MMM%20yyyy%20de',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.%20MMM%20yyyy%20de',
      },
    },
  },
  {
    id: 'MM/dd/yyyy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|1[0-2])\\/(0?[1-9]|[12][0-9]|30|31)\\/20[0-9]{2}',
    mask: 'MM/dd/yyyy',
    locale: null,
    displayName: {
      de: '(z.B. 11/17/2023)',
      en: '(e.g. 11/17/2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MM/dd/yyyy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MM/dd/yyyy',
      },
    },
  },
  {
    id: 'MM-dd-yyyy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|30|31)-20[0-9]{2}',
    mask: 'MM-dd-yyyy',
    locale: null,
    displayName: {
      de: '(z.B. 11-17-2023)',
      en: '(e.g. 11-17-2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MM-dd-yyyy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MM-dd-yyyy',
      },
    },
  },
  {
    id: 'MMMM dd, yyyy en',
    dataType: 'DATE',
    regex:
      '\\b(January|February|March|April|May|June|July|August|September|October|November|December) (0?[1-9]|[12][0-9]|30|31), 20[0-9]{2}',
    mask: 'MMMM dd, yyyy',
    locale: 'en',
    displayName: {
      de: '(z.B. January 17, 2023)',
      en: '(e.g. January 17, 2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MMMM%20dd,%20yyyy%20en',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MMMM%20dd,%20yyyy%20en',
      },
    },
  },
  {
    id: 'dd/MM/yyyy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|[12][0-9]|30|31)\\/(0?[1-9]|1[0-2])\\/20[0-9]{2}',
    mask: 'dd/MM/yyyy',
    locale: null,
    displayName: {
      de: '(z.B. 17/01/2023)',
      en: '(e.g. 17/01/2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd/MM/yyyy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd/MM/yyyy',
      },
    },
  },
  {
    id: 'dd-MMM-yyyy en',
    dataType: 'DATE',
    regex:
      '\\b(0?[1-9]|[12][0-9]|30|31)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-20[0-9]{2}',
    mask: 'dd-MMM-yyyy',
    locale: 'en',
    displayName: {
      de: '(z.B. 17-Jan-2023)',
      en: '(e.g. 17-Jan-2023)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MMM-yyyy%20en',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MMM-yyyy%20en',
      },
    },
  },
  {
    id: 'dd-MM-yy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|[12][0-9]|30|31)-(0?[1-9]|1[0-2])-[0-9]{2}',
    mask: 'dd-MM-yy',
    locale: null,
    displayName: {
      de: '(z.B. 17-11-23)',
      en: '(e.g. 17-11-23)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MM-yy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MM-yy',
      },
    },
  },
  {
    id: 'dd-MMM-yy en',
    dataType: 'DATE',
    regex:
      '\\b(0?[1-9]|[12][0-9]|30|31)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-[0-9]{2}',
    mask: 'dd-MMM-yy',
    locale: 'en',
    displayName: {
      de: '(z.B. 17-Jan-23)',
      en: '(e.g. 17-Jan-23)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MMM-yy%20en',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd-MMM-yy%20en',
      },
    },
  },
  {
    id: 'dd.MM.yy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|[12][0-9]|30|31)\\.(0?[1-9]|1[0-2])\\.[0-9]{2}\\b',
    mask: 'dd.MM.yy',
    locale: null,
    displayName: {
      de: '(z.B. 17.11.23)',
      en: '(e.g. 17.11.23)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.MM.yy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd.MM.yy',
      },
    },
  },
  {
    id: 'MM/dd/yy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|1[0-2])\\/(0?[1-9]|[12][0-9]|30|31)\\/[0-9]{2}',
    mask: 'MM/dd/yy',
    locale: null,
    displayName: {
      de: '(z.B. 11/17/23)',
      en: '(e.g. 11/17/23)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MM/dd/yy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/MM/dd/yy',
      },
    },
  },
  {
    id: 'dd/MM/yy',
    dataType: 'DATE',
    regex: '\\b(0?[1-9]|[12][0-9]|30|31)\\/(0?[1-9]|1[0-2])\\/[0-9]{2}',
    mask: 'dd/MM/yy',
    locale: null,
    displayName: {
      de: '(z.B. 17/01/23)',
      en: '(e.g. 17/01/23)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd/MM/yy',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/dd/MM/yy',
      },
    },
  },
  {
    id: 'decimal dot',
    dataType: 'DECIMAL',
    regex: null,
    mask: '.',
    locale: null,
    displayName: {
      de: '. (Punkt)',
      en: '. (Dot)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/decimal%20dot',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/decimal%20dot',
      },
    },
  },
  {
    id: 'decimal comma',
    dataType: 'DECIMAL',
    regex: null,
    mask: ',',
    locale: null,
    displayName: {
      de: ', (Komma)',
      en: ', (comma)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/decimal%20comma',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/decimal%20comma',
      },
    },
  },
  {
    id: 'decimal arabic',
    dataType: 'DECIMAL',
    regex: null,
    mask: '٫',
    locale: null,
    displayName: {
      de: '٫ (Arabisch)',
      en: '٫ (Arabic)',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/decimal%20arabic',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/decimal%20arabic',
      },
    },
  },
  {
    id: 'whole number',
    dataType: 'DECIMAL',
    regex: null,
    mask: '',
    locale: null,
    displayName: {
      de: 'Ganze Zahl',
      en: 'Whole number',
    },
    match: {},
    _links: {
      self: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/whole%20number',
      },
      dataFormat: {
        href: 'https://api.test.connect.sly.swiss/upload-service-f/dataformats/whole%20number',
      },
    },
  },
]

export const DATE_DATA_FORMAT_ID_MOCK = 'yyyy-MM-dd'
export const DECIMAL_DATA_FORMAT_ID_MOCK = 'decimal dot'

export const CART_DATE_FORMAT_MOCK = dataFormatsMock.find(
  (df) => df.id === DATE_DATA_FORMAT_ID_MOCK
)
export const CART_DECIMAL_FORMAT_MOCK = dataFormatsMock.find(
  (df) => df.id === DECIMAL_DATA_FORMAT_ID_MOCK
)
