import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getLastKey } from '../utils'

export const Tutorial = ({
  currentField,
  currentStep,
  onNext,
  isExpanded,
  onToggle,
  customFields,
  locale,
}) => {
  const isNextShown =
    currentStep !== `${getLastKey(customFields)}-click-next` && currentStep !== 'end'

  const [currentStepKey, setCurrentStepKey] = useState(currentField)

  const tooFew = <FormattedMessage id="tutorial.matched.too.few" defaultMessage="too few" />

  const tooMany = <FormattedMessage id="tutorial.matched.too.many" defaultMessage="many" />

  const actionMore = (
    <FormattedMessage id="advancedView.filters.similarity.more.label" defaultMessage="more" />
  )

  const actionLess = (
    <FormattedMessage id="advancedView.filters.similarity.less.label" defaultMessage="less" />
  )

  useEffect(() => {
    if (currentStep !== 'end') {
      var currentStepSplitted = currentStep.split('-')
      if (currentStepSplitted.length > 0) {
        setCurrentStepKey(currentStepSplitted[0])
      }
    }
  }, [currentStep])

  return (
    <>
      <div className="uta-tutorial-header">
        <h3>
          <FormattedMessage id="tutorial.title" defaultMessage="How to import your PDF" />
        </h3>
        <div className="uta-toggle-tutorial">
          <input
            type="checkbox"
            id="toggleTutorial"
            onChange={onToggle}
            checked={isExpanded ? 'checked' : ''}
          />
          <label className="uta-label" htmlFor="toggleTutorial">
            <FormattedMessage id="tutorial.toggle.label" defaultMessage="Toggle tutorial" />
          </label>
        </div>
      </div>

      {isExpanded && (
        <div className="uta-tutorial">
          <ol className="uta-steps">
            {customFields[currentStepKey] &&
              customFields[currentStepKey].defaults.isUnique &&
              currentStep !== 'end' && (
                <>
                  <li
                    className={`uta-step ${
                      currentStep === `${currentStepKey}-click-button` ? 'uta-current' : ''
                    }`}
                  >
                    <FormattedMessage
                      id="tutorial.click.button"
                      defaultMessage={`Click on the highlighted '${customFields[currentStepKey].labels[locale]}' button below;`}
                      values={{
                        field: `${customFields[currentStepKey].labels[locale]}`,
                      }}
                    />
                  </li>
                  <li
                    className={`uta-step ${
                      currentStep === `${currentStepKey}-click-location` ? 'uta-current' : ''
                    }`}
                  >
                    <FormattedMessage
                      id="tutorial.click.location"
                      defaultMessage={`Click on the location on the highligthed PDF where your ${customFields[currentStepKey].labels[locale]} is located;`}
                      values={{
                        count: 1,
                        field: `${customFields[currentStepKey].labels[locale]}`,
                      }}
                    />
                  </li>
                  {currentStepKey !== getLastKey(customFields) && (
                    <li
                      className={`uta-step ${
                        currentStep === `${currentStepKey}-click-next` ? 'uta-current' : ''
                      }`}
                    >
                      <FormattedMessage
                        id="tutorial.verify.and.click.next"
                        defaultMessage="Verify the selection on the PDF is correct and continue by clicking the 'Next' button."
                      />
                    </li>
                  )}
                  {currentStepKey === getLastKey(currentStepKey) && (
                    <li>
                      <FormattedMessage
                        id="tutorial.click.add.to.cart"
                        defaultMessage="Finish by clicking the 'Add to cart' button."
                      />
                    </li>
                  )}
                </>
              )}
            {customFields[currentStepKey] &&
              !customFields[currentStepKey].defaults.isUnique &&
              currentStep !== 'end' && (
                <>
                  <li
                    className={`uta-step ${
                      currentStep === `${currentStepKey}-click-button` ? 'uta-current' : ''
                    }`}
                  >
                    <FormattedMessage
                      id="tutorial.click.button"
                      defaultMessage={`Click on the highlighted '${customFields[currentStepKey].labels[locale]} button below;`}
                      values={{
                        field: `${customFields[currentStepKey].labels[locale]}`,
                      }}
                    />
                  </li>
                  <li
                    className={`uta-step ${
                      currentStep === `${currentStepKey}-click-location` ? 'uta-current' : ''
                    }`}
                  >
                    <FormattedMessage
                      id="tutorial.click.location"
                      defaultMessage={`Click on the location on the highligthed PDF where your ${customFields[currentStepKey].labels[locale]} are located (pick the first from the top);`}
                      values={{
                        count: 2,
                        field: `${customFields[currentStepKey].labels[locale]}`,
                      }}
                    />
                  </li>
                  <li
                    className={`uta-step ${
                      currentStep === `${currentStepKey}-click-next` ? 'uta-current' : ''
                    }`}
                  >
                    <FormattedMessage
                      id="tutorial.verify"
                      defaultMessage="Verify the selection on the PDF is correct:"
                    />
                    <ul>
                      <li>
                        <FormattedMessage
                          id="tutorial.verify.if"
                          defaultMessage="If too less are selected, press the 'More' button until it
                    matches all entries;"
                          values={{
                            matched: tooFew,
                            actionName: actionMore,
                          }}
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="tutorial.verify.if"
                          defaultMessage="If too many are selected, press the 'Less' button until it
                    matches all entries;"
                          values={{
                            matched: tooMany,
                            actionName: actionLess,
                          }}
                        />
                      </li>
                      {currentStepKey !== getLastKey(customFields) && (
                        <li>
                          <FormattedMessage
                            id="tutorial.click.next"
                            defaultMessage="Continue by clicking the 'Next' button."
                          />
                        </li>
                      )}
                    </ul>
                  </li>
                  {currentStepKey === getLastKey(customFields) && (
                    <li>
                      <FormattedMessage
                        id="tutorial.click.add.to.cart"
                        defaultMessage="Finish by clicking the 'Add to cart' button."
                      />
                    </li>
                  )}
                </>
              )}
          </ol>

          {currentStep === 'end' && (
            <span>
              <FormattedMessage
                id="tutorial.congrats"
                defaultMessage="Congratulations! The format you chose has been automatically saved and will be used to automatically parse the next PDF."
              />
            </span>
          )}
          <div className="uta-actions">
            {isNextShown && (
              <button
                onClick={() => onNext({ field: currentField, next: 'field' })}
                className={`uta-button uta-next ${
                  currentStep && currentStep.includes('next') ? 'uta-tut-highlighted' : ''
                }`}
              >
                <FormattedMessage id="tutorial.next" defaultMessage="Next" />
              </button>
            )}
          </div>
        </div>
      )}
    </>
  )
}
