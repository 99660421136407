import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { api } from '../api'
import { FormattedMessage } from 'react-intl'

const { REACT_APP_USE_MOCKS: USE_MOCKS } = process.env

export const Dropzone = ({ onFileDropped, onFileUploading, onFileUploadError, shopId }) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      onFileUploading()
      const fileType = acceptedFiles[0].name.substr(acceptedFiles[0].name.lastIndexOf('.') + 1)

      await api.upload({
        useMocks: !!parseInt(USE_MOCKS),
        file: acceptedFiles[0],
        onSuccess: onFileDropped,
        onError: onFileUploadError,
        fileType: fileType,
        key: null,
        shopId: shopId,
      })
    },
    [onFileDropped, onFileUploadError]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p className="uta-text">
        {isDragActive ? (
          `Drop the files here...`
        ) : (
          <FormattedMessage
            id={`dropzone.placeholder`}
            defaultMessage={`Drag 'n' drop your file here, or click to select it.`}
          />
        )}
      </p>
    </div>
  )
}
