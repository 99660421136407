import React from 'react'
import TableItem from './TableItem'
import { FormattedMessage } from 'react-intl'

const Table = ({ items, onAddToCart, onCancelOrder }) => {
  const renderParsedFile = (item) => {
    return <TableItem key={Math.random()} item={item} />
  }

  return (
    <div className="uta-table">
      <div className="uta-actions">
        <button
          id="utaCancelOrder"
          className={`uta-button uta-cancel-order`}
          onClick={onCancelOrder}
        >
          <FormattedMessage id="actions.cancel.label" defaultMessage="cancel" />
        </button>

        <button
          id="utaAddToCartSubmit"
          className={`uta-button uta-add-to-cart
          `}
          onClick={onAddToCart}
        >
          <FormattedMessage id="actions.addToCart.label" defaultMessage="add to cart" />
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th colSpan="3">Article number</th>
            <th colSpan="3">Quantity</th>
            <th colSpan="6">Comment</th>
          </tr>
        </thead>
        <tbody>{items.map((item) => renderParsedFile(item))}</tbody>
      </table>
    </div>
  )
}

export default Table
