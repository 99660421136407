import axios from 'axios'
import { processHtml } from '../utils'
import { ENV } from '../config'
import { CURRENT_MOCK } from '../mocks'

const PARSER_ENDPOINT = ENV.REACT_APP_PARSER_ENDPOINT

export const PDFTablesAPI = async ({ file, onSuccess }) => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await axios.post(
    'https://pdftables.com/api?key=m2iv2eocfzh5&format=html',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  onSuccess(data)
}

export const api = {
  upload: async ({ useMocks, file, onSuccess, onError, key, shopId }) => {
    if (useMocks) {
      // TODO check code splitting
      const { BuildVU } = require('../mocks/BuildVU')

      const output = BuildVU[CURRENT_MOCK]

      // if true, the input probably required OCR, which is not supported right now
      // TODO this could happen due to network errors as well
      if (!output.includes('text/css')) {
        return onError('ocr-needed')
      } else {
        onSuccess(processHtml(output, 'http://localhost:3000/upload-service/'), true)
      }

      return
    }

    parserAPI.upload({
      file,
      onSuccess,
      onError,
      key,
      shopId,
    })
  },
}

export const parserAPI = {
  upload: async ({ file, onSuccess, onError, key, shopId }) => {
    let i = 0

    const buildOutput = async (previewUrl) => {
      // index.html contains some JS that outputs the pages, we need the raw html of the pages instead
      let output = ''
      let j = 0

      try {
        while (j++ < 10) {
          const url = previewUrl.replace('index', j)

          const { data } = await axios.get(url)

          output += data
        }
      } catch (e) {
        // TODO here we should:
        // 1. catch cors-related errors;
        // 2. catch 404's; we probably need a better approach in loading the BuildVU output
      }

      // if true, the input probably required OCR, which is not supported right now
      // TODO this could happen due to network errors as well
      if (!output.includes('text/css')) return onError('ocr-needed')
      else
        onSuccess(processHtml(output, previewUrl.substring(0, previewUrl.indexOf('index'))), true)
    }

    const poll = async (uuid) => {
      const { data } = await axios.get(`${PARSER_ENDPOINT}?uuid=${uuid}&rand=${Math.random()}`)

      const { state } = data

      if (state.toLowerCase() === 'processed') {
        if (data.hasOwnProperty('document')) {
          return onSuccess(data, false)
        } else if (data.hasOwnProperty('previewUrl')) {
          // fix for local testing
          if (data.previewUrl.startsWith('http://localhost')) {
            buildOutput(data.previewUrl)
          } else {
            buildOutput(data.previewUrl.replace('http://', 'https://'))
          }
        } else {
          throw { response: { status: 400 } }
        }
      } else if (state.toLowerCase() === 'error') {
        throw { response: { status: 400 } }
      } else if (i++ <= 20) setTimeout(() => poll(uuid), 3000)
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('input', 'upload')

    try {
      const {
        data: { uuid },
      } = key
        ? await axios.get(`${PARSER_ENDPOINT}/${key}`, {
            headers: {
              'X-API-Key': shopId,
            },
          })
        : await axios.post(`${PARSER_ENDPOINT}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-API-Key': shopId,
            },
          })

      await poll(uuid)
    } catch (e) {
      if (e.response.status === 500 || e.response.status === 400) {
        if (
          e.response.data &&
          e.response.data.message &&
          e.response.data.message.includes('Cached document for provided key')
        ) {
          return onError('not-existing-document')
        }
        return onError('wrong-format')
      } else if (e.response.status === 403) {
        return onError('missing-api-key')
      } else if (e.response.status === 429) {
        return onError('limit-exceeded')
      } else if (e.response.status === 413) {
        return onError('upload-file-limit-exceeded')
      }
      return onError('generic')
    }
  },
}
