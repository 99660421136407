import de from './de.json'
import en from './en.json'
import fr from './fr.json'
import it from './it.json'
import nl from './nl.json'
import cs from './cs.json'
import pl from './pl.json'

export const locales = {
  de,
  en,
  fr,
  it,
  nl,
  cs,
  pl,
}

export default locales
