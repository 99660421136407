import React from 'react';
import {getSimilarity} from '../utils';
import {FormattedMessage} from 'react-intl';
import {Col, Row} from 'react-bootstrap';

const MIN_SIMILARITY = 1;
const MAX_SIMILARITY = 25;

export const ButtonsRow = ({
  isLayoutCompact,
  onFieldSelectorClick,
  onFormatChange,
  selection,
  format,
  tutCurrentStep,
  isAdvancedViewShown,
  choicesTexts,
  setChoicesTexts,
  customFields,
  locale,
  clicked,
  setClicked,
  startPos,
  endPos,
}) => {
  const [fields, setFields] = React.useState({});

  // sometimes the sliders flicker between two values when adjusting automatically
  // (e.g. when ArtNr has 5 matches and Qty can only have, say, 2 or 7, and Qty starts flickering between the two);
  // we fix this by limiting the number of adjustments on the sliders
  const [qtySliderIterations, setQtySliderIterations] = React.useState(
      MAX_SIMILARITY + 1);
  const [artRefSliderIterations, setArtRefSliderIterations] = React.useState(
      MAX_SIMILARITY + 1);

  const [slideQtyTimeoutId, setSlideQtyTimeoutId] = React.useState(0);
  const [slideArtRefTimeoutId, setSlideArtRefTimeoutId] = React.useState(0);

  const getFields = () =>
      Object.keys(customFields || {}).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              ...customFields[key],
              hasBeenSelected: selection[key].length > 0,
              count: `(${
                  selection[key].filter(
                      (v) =>
                          // we might have undefined values, so we filter them out;
                          // undefined values happen when no values could be extracted from a specific order position due to the position having a weird layout
                          !!v,
                  ).length
              })`,
              relevancy: getSimilarity({format, key, customFields}),
            },
          }),
          {},
      );

  React.useEffect(() => {
    setFields(getFields());
  }, [selection, choicesTexts]);

  React.useEffect(() => {
    if (qtySliderIterations <= MAX_SIMILARITY / 2) {
      window.clearTimeout(slideQtyTimeoutId);

      setSlideQtyTimeoutId(window.setTimeout(updateQtyMatches, 50));
    }
  }, [selection, qtySliderIterations, customFields]);

  React.useEffect(() => {
    if (artRefSliderIterations <= MAX_SIMILARITY / 2) {
      window.clearTimeout(slideArtRefTimeoutId);

      setSlideArtRefTimeoutId(window.setTimeout(updateArtRefsMatches, 50));
    }
  }, [selection, artRefSliderIterations, customFields]);

  const onLess = (event) => {
    const {
      target: {name},
    } = event;
    const [key] = name.split('-');

    const similarity = +format[key].relevancy;

    setChoicesTexts((prev) => ({...prev, [key]: []}));

    if (similarity > 0) {
      onFormatChange({key: name, value: similarity - 1});
    }
  };

  const onMore = (event) => {
    const {
      target: {name},
    } = event;
    const [key] = name.split('-');

    setChoicesTexts((prev) => ({...prev, [key]: []}));

    const similarity = +format[key].relevancy;

    if (similarity < MAX_SIMILARITY) {
      onFormatChange({key: name, value: similarity + 1});
    }
  };

  const onSimilarityChange = (event) => {
    // TODO later: support keyboard events

    const {
      target: {name},
    } = event;

    if (name.includes('artNr') && (selection.qty || selection.artRef)) {
      setQtySliderIterations(0);
      setArtRefSliderIterations(0);
    }
  };

  // TODO still needed? doesn't seem to work very well
  // we wanna tie the number of lighted quantities to the number of lighted article numbers, which we consider our "main" key
  const updateQtyMatches = () => {
    const artNrMatches = selection.artNr.length;
    const qtyMatches = selection.qty.length;

    if (
        // we have some artNrs
        !!artNrMatches &&
        // we have some qtys
        !!qtyMatches &&
        // we have a different number of artNrs vs. qtys
        qtyMatches !== artNrMatches &&
        // we have more quantities than art numbers, and current quantity similarity is not below minimum
        ((qtyMatches > artNrMatches && +format.qty.relevancy >= MIN_SIMILARITY)
            ||
            // we have less quantities than art numbers, and current quantity similarity is not above maximum
            (qtyMatches < artNrMatches && +format.qty.relevancy
                <= MAX_SIMILARITY))
    ) {
      setQtySliderIterations(qtySliderIterations + 1);

      if (qtyMatches >= artNrMatches) {
        onLess({target: {name: 'qty-similarity'}});
      } else {
        onMore({target: {name: 'qty-similarity'}});
      }
    }
  };

  // TODO still needed? doesn't seem to work very well
  // we wanna tie the number of lighted article references to the number of lighted article numbers, which we consider our "main" key
  const updateArtRefsMatches = () => {
    const artNrMatches = selection.artNr.length;
    const artRefMatches = selection.artRef.length;

    if (
        // we have some artNrs
        !!artNrMatches &&
        // we have some artRefs
        !!artRefMatches &&
        // we have a different number of artNrs vs. artRefs
        artRefMatches !== artNrMatches &&
        // we have more art references than art numbers, and current artRef similarity is not below minimum
        ((artRefMatches > artNrMatches && +format.artRef.relevancy
                >= MIN_SIMILARITY) ||
            // we have less art references than art numbers, and current artRef similarity is not above maximum
            (artRefMatches < artNrMatches && +format.artRef.relevancy
                <= MAX_SIMILARITY))
    ) {
      setArtRefSliderIterations(artRefSliderIterations + 1);

      if (artRefMatches >= artNrMatches) {
        onLess({target: {name: 'artRef-similarity'}});
      } else {
        onMore({target: {name: 'artRef-similarity'}});
      }
    }
  };

  const onClick = (key) => {
    setClicked(key);
    onFieldSelectorClick(key);
  };

  const getTutLightClass = (key) => {
    if (tutCurrentStep === `${key}-click-button`) {
      return 'uta-tut-highlighted';
    }

    return '';
  };

  return (
      <Row className="uta-row-selectors">
        {Object.keys(fields || {}).map((k, i) => {
          const field = fields[k];

          // print out only the ones between the specified start and end positions
          if (!(i >= startPos && i < endPos)) {
            return null;
          }

          // e.g. `uta-button uta-selector field_1 artNr uta-selected `
          const className = `uta-button uta-selector field_${i} ${k}${
              field.hasBeenSelected ? ' uta-selected' : ''
          }${clicked === k ? ' uta-clicked' : ''} ${getTutLightClass(k)}${
              k === 'qty' ? 'uta-quantity' : ''
          }`;

          return (
              <Col md={3} key={k}>
                <div key={k} className="uta-button-container">
                  <button id={k} onClick={() => onClick(k)}
                          className={className}>
                    {field.labels[locale] || field.labels.en} {field.count}{' '}
                    {`${field.required ? '*' : ''}`}
                  </button>

                  {isAdvancedViewShown && (
                      <div className="uta-advanced-view">
                        <div className="uta-sliders">
                          <div className="uta-advanced-view-labels">
                            <label className="uta-label">
                              <FormattedMessage
                                  id="advancedView.filters.similarity.label"
                                  defaultMessage="similarity"
                              />
                            </label>
                          </div>

                          <button
                              name={`${k}-similarity`}
                              onClick={(e) => {
                                onLess(e);
                                onSimilarityChange(e);
                              }}
                              className={`uta-button ${
                                  tutCurrentStep && tutCurrentStep
                                  === `${k}-click-next`
                                      ? 'uta-tut-highlighted'
                                      : ''
                              }`}
                          >
                            {isLayoutCompact ? '-' :
                                <FormattedMessage
                                    id="advancedView.filters.similarity.less.label"
                                    defaultMessage="less"
                                />
                            }
                          </button>
                          <button
                              name={`${k}-similarity`}
                              onClick={(e) => {
                                onMore(e);
                                onSimilarityChange(e);
                              }}
                              className={`uta-button ${
                                  tutCurrentStep && tutCurrentStep
                                  === `${k}-click-next`
                                      ? 'uta-tut-highlighted'
                                      : ''
                              }`}
                          >
                            {isLayoutCompact ? '+' :
                                <FormattedMessage
                                    id="advancedView.filters.similarity.more.label"
                                    defaultMessage="more"
                                />
                            }
                          </button>
                        </div>
                      </div>
                  )}
                </div>
              </Col>
          );
        })}
      </Row>
  );
};
