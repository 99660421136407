import React from 'react'
import {getLastKey} from '../utils'
import {FormattedMessage} from 'react-intl'
import {Container} from 'react-bootstrap'
import {ButtonsRow} from './ButtonsRow'

const ErrorsBadge = ({isVisible}) => (
    <div className={`uta-errors-badge ${isVisible ? 'uta-visible' : ''}`}/>
)

const ErrorsPopup = ({errors, isVisible}) => {
  return (
      <ul className={`uta-errors-list ${isVisible ? 'uta-visible' : ''}`}>
        {errors.map((e) => (
            <li className="uta-error" key={e}>
              <FormattedMessage id={`error.${e}`} defaultMessage={e}/>
            </li>
        ))}
      </ul>
  )
}

export const Buttons = ({
  onFieldSelectorClick,
  onFormatChange,
  onAddToCart,
  isLayoutCompact,
  onCancelOrder,
  selection,
  format,
  tutCurrentStep,
  isAdvancedViewShown,
  onAdvancedViewToggle,
  choicesTexts,
  setChoicesTexts,
  customFields,
  currentField,
  locale,
  addToCartErrors,
}) => {
  const [clicked, setClicked] = React.useState(currentField)
  const [startPos, setStartPos] = React.useState(0)
  const [endPos, setEndPos] = React.useState(0)

  const [isErrorsPopupVisible, setIsErrorsPopupVisible] = React.useState(false)

  React.useEffect(() => {
    const startPos = 0
    const endPos = Object.keys(customFields).length
    setStartPos(startPos)
    setEndPos(endPos)
  }, [customFields])

  return (
      <div className="uta-buttons">
        <div className="uta-buttons-header">
          <h3>
            <FormattedMessage id="advancedView.title"
                              defaultMessage="PDF import settings"/>
          </h3>
        </div>

        <Container className="uta-selectors">
          <ButtonsRow
              {...{
                isLayoutCompact,
                onFieldSelectorClick,
                onFormatChange,
                selection,
                format,
                tutCurrentStep,
                isAdvancedViewShown,
                choicesTexts,
                setChoicesTexts,
                customFields,
                locale,
                clicked,
                setClicked,
                startPos,
                endPos,
              }}
          />
        </Container>

        <div className="uta-actions">
          <button
              id="utaCancelOrder"
              className={`uta-button uta-cancel-order`}
              onClick={onCancelOrder}
          >
            <FormattedMessage id="actions.cancel.label"
                              defaultMessage="cancel"/>
          </button>

          <div className="uta-toggle-advanced-view">
            <input
                type="checkbox"
                className="uta-advanced-view"
                onChange={onAdvancedViewToggle}
                id="advanced-view"
                checked={isAdvancedViewShown}
            />
            <label htmlFor="advanced-view" className="uta-label">
              <FormattedMessage id="advancedView.toggle.label"
                                defaultMessage="advanced view"/>
            </label>
          </div>

          <div
              className="uta-add-to-cart"
              onMouseEnter={() => {
                if (addToCartErrors.length) {
                  setIsErrorsPopupVisible(true)
                }
              }}
              onMouseLeave={() => {
                setIsErrorsPopupVisible(false)
              }}
          >
            <ErrorsBadge isVisible={!!addToCartErrors.length}/>
            <ErrorsPopup errors={addToCartErrors}
                         isVisible={isErrorsPopupVisible}/>

            <button
                id="utaAddToCartSubmit"
                className={`uta-button uta-add-to-cart-button ${
                    tutCurrentStep === `${getLastKey(customFields)}-click-next`
                        ? 'uta-tut-highlighted'
                        : ''
                }`}
                disabled={addToCartErrors.length}
                onClick={onAddToCart}
            >
              <FormattedMessage id="actions.addToCart.label"
                                defaultMessage="add to cart"/>
            </button>
          </div>
        </div>
      </div>
  )
}
