import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { IntlProvider } from 'react-intl'
import i18nMessages from './i18n'
import { Cart } from './types'
import { logCart } from './utils'

const searchParams = new URLSearchParams(window.location.search)

const { orderParser } = window

const rootId = (orderParser && orderParser.rootId) || 'root'

const onCartImport = (orderParser && orderParser.onCartImport) || logCart

const customerId =
  (orderParser && orderParser.configurationId) ||
  (orderParser && orderParser.customerId) ||
  searchParams.get('configurationId') ||
  searchParams.get('customerId')

const shopId = (orderParser && orderParser.shopId) || searchParams.get('shopId')

const cacheKey =
  (orderParser && orderParser.cacheKey) || searchParams.get('cacheKey') || searchParams.get('key')

const locale =
  (orderParser && orderParser.locale) ||
  (navigator.language && navigator.language.includes('-')
    ? navigator.language.substr(0, navigator.language.indexOf('-'))
    : navigator.language)

const useDefaultCSS = !orderParser

if (useDefaultCSS) require('./index.css')

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={locale} key={locale} messages={i18nMessages[locale]}>
      <App {...{ onCartImport, useDefaultCSS, customerId, cacheKey, shopId, locale }} />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById(rootId)
)
